import {
  STUDENTS_FETCH_REQUESTED,
  STUDENTS_FETCH_SUCCEEDED,
  STUDENT_FETCH_REQUESTED,
  STUDENT_FETCH_SUCCEEDED,
  STUDENT_NOT_FOUND,
  STUDENT_SAVE_REQUESTED,
  STUDENT_SAVE_SUCCEEDED,
  STUDENT_SAVE_FAILED,
  UPDATE_STUDENTS_QUERY_SORT,
  UPDATE_STUDENTS_QUERY_SEARCH,
  UPDATE_STUDENTS_QUERY_FILTER,
  RESET_STUDENTS_QUERY,
} from './actionTypes';

export const studentsFetchRequested = (query) => ({
  type: STUDENTS_FETCH_REQUESTED,
  query,
});

export const updateStudentsQuerySort = (id, asc) => ({
  type: UPDATE_STUDENTS_QUERY_SORT,
  id,
  asc,
});

export const updateStudentsQuerySearch = (search) => ({
  type: UPDATE_STUDENTS_QUERY_SEARCH,
  search,
});

export const updateStudentsQueryFilter = (filterId, optionValue) => ({
  type: UPDATE_STUDENTS_QUERY_FILTER,
  filterId,
  optionValue,
});

export const resetStudentsQuery = () => ({
  type: RESET_STUDENTS_QUERY,
});

export const studentsFetchSucceeded = ({ page, next = null }) => ({
  type: STUDENTS_FETCH_SUCCEEDED,
  students: page,
  next,
});

export const studentFetchRequested = (id) => ({
  type: STUDENT_FETCH_REQUESTED,
  id,
});

export const studentFetchSucceeded = ({ student }) => ({
  type: STUDENT_FETCH_SUCCEEDED,
  student,
});

export const studentNotFound = () => ({
  type: STUDENT_NOT_FOUND,
});

export const studentSaveRequested = ({ id, data }) => ({
  type: STUDENT_SAVE_REQUESTED,
  id,
  data,
});

export const studentSaveSucceeded = ({ student }) => ({
  type: STUDENT_SAVE_SUCCEEDED,
  student,
});

export const studentSaveFailed = ({ errors } = {}) => ({
  type: STUDENT_SAVE_FAILED,
  errors,
});
