import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { studentsFetchSucceeded } from '../actions';
import { STUDENTS_FETCH_REQUESTED } from '../actionTypes';

function* fetchStudents(action) {
  const res = yield call(api.fetchStudents, action.query);
  yield put(studentsFetchSucceeded(res));
}

function* fetchStudentsSaga() {
  yield takeLatest(STUDENTS_FETCH_REQUESTED, fetchStudents);
}

export default fetchStudentsSaga;
