import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setSbatsQuery } from '../actions';
import { REDIRECT_TO_SBATS_WITH_QUERY } from '../actionTypes';

function* redirectToSbatsWithQuery({ query }) {
  yield all([put(setSbatsQuery(query)), put(push('/sbats'))]);
}

function* redirectToSbatsWithQuerySaga() {
  yield takeLatest(REDIRECT_TO_SBATS_WITH_QUERY, redirectToSbatsWithQuery);
}

export default redirectToSbatsWithQuerySaga;
