import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

import AutoCompleteListBox from './AutoCompleteListBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiAutoCompleteInput = ({
  options,
  label,
  name,
  control,
  hasError,
  errorText,
  defaultValue,
  disabled = false,
}) => {
  return (
    <Controller
      as={
        <Autocomplete
          disableCloseOnSelect
          options={options}
          getOptionLabel={(option) => option.name}
          multiple
          ListboxComponent={AutoCompleteListBox}
          ListboxProps={{ itemSize: 48 }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option.name}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              error={hasError}
              helperText={errorText}
            />
          )}
        />
      }
      onChange={([, data]) => data}
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};

export default MultiAutoCompleteInput;
