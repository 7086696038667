import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { externalSbatFetchSucceeded, externalSbatNotFound } from '../actions';
import { EXTERNAL_SBAT_FETCH_REQUESTED } from '../actionTypes';

function* fetchExternalSbat(action) {
  try {
    const data = yield call(api.fetchExternalSbat, action.id, action.token);
    yield put(externalSbatFetchSucceeded(data));
  } catch (e) {
    if (e.response) {
      if (
        e.response.status === 404 ||
        e.response.status === 403 ||
        e.response.status === 401
      ) {
        yield put(externalSbatNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchExternalSbatSaga() {
  yield takeLatest(EXTERNAL_SBAT_FETCH_REQUESTED, fetchExternalSbat);
}

export default fetchExternalSbatSaga;
