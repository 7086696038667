import React from 'react';
import Grid from '@material-ui/core/Grid';

import AutoCompleteInput from '../form/AutoCompleteInput';
import useEmployers from '../../hooks/useEmployers';

const SelectEmployer = ({ initialEmployer, control, errors, disabled }) => {
  const { employers } = useEmployers();
  const defaultEmployer = initialEmployer
    ? employers.find((employer) => {
        return employer.id === initialEmployer.id;
      })
    : null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        <AutoCompleteInput
          options={employers}
          label="Select a employer"
          key="employer"
          name="employer"
          control={control}
          defaultValue={defaultEmployer}
          disabled={disabled}
          hasError={Boolean(errors.employer)}
          errorText={errors.employer && 'Required'}
        />
      </Grid>
    </Grid>
  );
};

export default SelectEmployer;
