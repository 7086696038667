import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { schoolsFetchSucceeded } from '../actions';
import { SCHOOLS_FETCH_REQUESTED } from '../actionTypes';

function* fetchSchools(action) {
  const res = yield call(api.fetchSchools, action.query);
  yield put(schoolsFetchSucceeded(res));
}

function* fetchSchoolsSaga() {
  yield takeLatest(SCHOOLS_FETCH_REQUESTED, fetchSchools);
}

export default fetchSchoolsSaga;
