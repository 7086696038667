import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import {
  schoolsFetchRequested,
  updateSchoolsQuerySort,
  updateSchoolsQuerySearch,
  updateSchoolsQueryFilter,
  resetSchoolsQuery,
} from '../../redux/schools/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';
import {
  FILTER_TYPE_SELECT,
  FILTER_TYPE_SELECT_MULTI,
  FILTER_TYPE_CHECKBOX,
} from '../resourceTable/filterTypes';
import useAuthUser from '../../hooks/useAuthUser';
import useRtos from '../../hooks/useRtos';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Schools = () => {
  const classes = useStyles();
  const { rtos } = useRtos();
  const { authUserIsOfficer, authUserIsAdmin, authUserIsSystem } = useAuthUser();
  const school_filters = useSelector((state) => state.apiConfig.config.school_filters);

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'Schools', key: 'schools' }]} />
      </Box>

      <ResourceTable
        headers={[
          { id: 'name', label: 'School Name', sortable: true },
          { id: 'rtos.name', label: 'TQI team', sortable: true },
          {
            id: 'assigned_officers',
            label: 'Assigned SBAT officers',
            sortable: false,
          },
          { id: 'school_performance_directorate', label: 'School performance directorate', sortable: true },
          { id: 'school_principal_network', label: 'School principal network', sortable: true },
          { id: 'asgs_remoteness', label: 'ASGS remoteness', sortable: true },
          { id: 'epp_region', label: 'EPP region', sortable: true },
          { id: 'epp_school_group', label: 'EPP school group', sortable: true },
          !authUserIsOfficer && { id: 'actions', label: '', sortable: false },
        ].filter(Boolean)}
        filters={[
          {
            id: 'rto_id',
            label: 'TQI team',
            type: FILTER_TYPE_SELECT,
            options: rtos.map((rto) => {
              return {
                label: rto.name,
                value: rto.id,
              };
            }),
          },
          authUserIsOfficer && {
            id: 'my_assigned_schools',
            label: 'My Assigned Schools',
            type: FILTER_TYPE_CHECKBOX,
          },
          {
            id: 'school_performance_directorate',
            label: 'School performance directorate',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.school_performance_directorate.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          {
            id: 'school_principal_network',
            label: 'School principal network',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.school_principal_network.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          {
            id: 'asgs_remoteness',
            label: 'ASGS remoteness',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.asgs_remoteness.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          {
            id: 'epp_region',
            label: 'EPP region',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.epp_region.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          {
            id: 'epp_school_group',
            label: 'EPP school group',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.epp_school_group.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
        ].filter(Boolean)}
        mapResourceToColumns={(school) => {
          return [
            { label: school.name },
            { label: school.rto.name },
            {
              label: school.assignedOfficers.map((user) => (
                <div key={user.id}>
                  {user.name.first} {user.name.last}
                </div>
              )),
            },
            { label: school.school_performance_directorate },
            { label: school.school_principal_network },
            { label: school.asgs_remoteness },
            { label: school.epp_region },
            { label: school.epp_school_group },
            !authUserIsOfficer && {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/schools/${school.id}`,
                      color: 'secondary',
                      text: 'Manage School',
                      key: 'manage-school',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={schoolsFetchRequested}
        isFetchingResourceSelector={(state) => state.schools.fetchingSchools}
        resourcesSelector={(state) => state.schools.schools}
        resourceQuerySelector={(state) => state.schools.query}
        updateResourceQuerySort={updateSchoolsQuerySort}
        updateResourceQuerySearch={updateSchoolsQuerySearch}
        updateResourceQueryFilter={updateSchoolsQueryFilter}
        resetResourceQuery={resetSchoolsQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/schools/export`}
        searchLabel="Search for schools"
      />
    </>
  );
};

export default Schools;
