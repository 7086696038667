export const AUTH_USER_FETCH_REQUESTED = 'AUTH_USER_FETCH_REQUESTED';
export const AUTH_USER_FETCH_FAILED = 'AUTH_USER_FETCH_FAILED';
export const AUTH_USER_FETCH_SUCCEEDED = 'AUTH_USER_FETCH_SUCCEEDED';

export const AUTH_USER_LOGOUT_REQUESTED = 'AUTH_USER_LOGOUT_REQUESTED';
export const AUTH_USER_LOGOUT_SUCCEEDED = 'AUTH_USER_LOGOUT_SUCCEEDED';

export const SYSTEM_USER_LOGIN_REQUESTED = 'SYSTEM_USER_LOGIN_REQUESTED';
export const SYSTEM_USER_LOGIN_FAILED = 'SYSTEM_USER_LOGIN_FAILED';
export const SYSTEM_USER_LOGIN_SUCCEEDED = 'SYSTEM_USER_LOGIN_SUCCEEDED';

export const AUTH_USER_REGISTER_REQUESTED = 'AUTH_USER_REGISTER_REQUESTED';
export const AUTH_USER_REGISTER_SUCCEEDED = 'AUTH_USER_REGISTER_SUCCEEDED';

export const AUTH_USER_PROFILE_SAVE_REQUESTED =
  'AUTH_USER_PROFILE_SAVE_REQUESTED';
export const AUTH_USER_PROFILE_SAVE_SUCCEEDED =
  'AUTH_USER_PROFILE_SAVE_SUCCEEDED';

export const AUTH_USER_IMPERSONATE_REQUESTED =
  'AUTH_USER_IMPERSONATE_REQUESTED';
export const AUTH_USER_IMPERSONATE_SUCCEEDED =
  'AUTH_USER_IMPERSONATE_SUCCEEDED';
