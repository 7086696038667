import React from 'react';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dot: (props) => ({
    borderColor: theme.sbatRoleColors[props.roleId],
    backgroundColor: theme.sbatRoleColors[props.roleId],
  }),
}));

const RoleColoredTimelineDot = ({ roleId }) => {
  const classes = useStyles({ roleId });
  return <TimelineDot classes={{ root: classes.dot }} />;
};

export default RoleColoredTimelineDot;
