import React from 'react';

import DetailsList from '../DetailsList';

const UserDetails = ({ user }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Name',
          text: `${user.name.first} ${user.name.last}`,
        },
        {
          label: 'Username',
          text: user.username,
        },
        {
          label: 'Role',
          text: user.role.name,
        },
        user.school && {
          label: 'School',
          text: user.school.name,
        },
        user.rto && {
          label: 'TQI team',
          text: user.rto.name,
        },
        {
          label: 'Status',
          text: user.status.name,
        },
      ].filter(Boolean)}
    />
  );
};

export default UserDetails;
