import {
  CONTACT_FETCH_REQUESTED,
  CONTACT_FETCH_SUCCEEDED,
  CONTACT_NOT_FOUND,
  CONTACTS_FETCH_REQUESTED,
  CONTACTS_FETCH_SUCCEEDED,
  CONTACT_SAVE_REQUESTED,
  CONTACT_SAVE_SUCCEEDED,
  CONTACT_SAVE_FAILED,
  UPDATE_CONTACTS_QUERY_SORT,
  UPDATE_CONTACTS_QUERY_SEARCH,
  RESET_CONTACTS_QUERY,
} from './actionTypes';

export const contactFetchRequested = (id) => ({
  type: CONTACT_FETCH_REQUESTED,
  id,
});

export const contactFetchSucceeded = ({ contact }) => ({
  type: CONTACT_FETCH_SUCCEEDED,
  contact,
});

export const contactNotFound = () => ({
  type: CONTACT_NOT_FOUND,
});

export const contactsFetchRequested = (query) => ({
  type: CONTACTS_FETCH_REQUESTED,
  query,
});

export const updateContactsQuerySort = (id, asc) => ({
  type: UPDATE_CONTACTS_QUERY_SORT,
  id,
  asc,
});

export const updateContactsQuerySearch = (search) => ({
  type: UPDATE_CONTACTS_QUERY_SEARCH,
  search,
});

export const resetContactsQuery = () => ({
  type: RESET_CONTACTS_QUERY,
});

export const contactsFetchSucceeded = ({ page, next = null }) => ({
  type: CONTACTS_FETCH_SUCCEEDED,
  contacts: page,
  next,
});

export const contactSaveRequested = ({ id, data }) => ({
  type: CONTACT_SAVE_REQUESTED,
  id,
  data,
});

export const contactSaveSucceeded = ({ contact }) => ({
  type: CONTACT_SAVE_SUCCEEDED,
  contact,
});

export const contactSaveFailed = ({ errors } = {}) => ({
  type: CONTACT_SAVE_FAILED,
  errors,
});
