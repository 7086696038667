import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

import AutoCompleteListBox from './AutoCompleteListBox';

const useStyles = makeStyles((theme) => ({
  required: {
    width: '100%',
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: theme.palette.required.border,
      },
    },
  },
}));

const AutoCompleteInputAsync = ({
  label,
  name,
  control,
  hasError,
  errorText,
  defaultValue,
  rules = { required: true },
  freeSolo,
  autoSelect,
  onSuggest,
  disabled,
  defaultOptions,
  requiredOnSubmit = false,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (disabled || !value) {
      setOptions(defaultOptions || []);
      if (loading) {
        setLoading(false);
      }
      return undefined;
    }

    // active flag is used to prevent prior async calls
    // from overwriting subsequent state changes
    let active = true;

    (async () => {
      setOptions([]);
      setLoading(true);
      const data = await onSuggest(value, setLoading);
      if (active) {
        setOptions(data);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [defaultOptions, disabled, value]);

  const onChange = (e, newValue, reason) => {
    // selecting an element triggers 'reset' event, don't suggest
    if (reason !== 'reset') {
      setValue(newValue);
    }
  };

  return (
    <Controller
      rules={rules}
      as={
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={onChange}
          getOptionLabel={(option) => (option.name && option.name) || option} // make compatible with freeSolo
          options={options}
          loading={loading}
          ListboxComponent={AutoCompleteListBox}
          ListboxProps={{ itemSize: 36 }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={requiredOnSubmit ? classes.required : ''}
              label={label}
              variant="outlined"
              fullWidth
              error={hasError}
              helperText={errorText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      }
      onChange={([, data]) => data}
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      freeSolo={freeSolo}
      autoSelect={autoSelect}
      disabled={disabled}
    />
  );
};

export default AutoCompleteInputAsync;
