import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(15),
  },
}));

const UnexpectedError = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h1">Oops...</Typography>
      <Typography variant="h6">
        An unexpected error occurred, please reload your page and try again. If
        the problem persists, please contact support.
      </Typography>
    </Box>
  );
};

export default UnexpectedError;
