import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';

import { studentFetchRequested } from '../../redux/actions';
import Breadcrumbs from '../Breadcrumbs';
import Error404 from '../errors/Error404';
import TabSections from '../TabSections';
import StudentDetails from './StudentDetails';
import ResourceHistory from '../history/ResourceHistory';
import StudentForm from './StudentForm';

const Student = () => {
  const dispatch = useDispatch();

  const student = useSelector((state) => state.students.student);
  const studentNotFound = useSelector(
    (state) => state.students.studentNotFound
  );
  const savingStudent = useSelector((state) => state.students.savingStudent);

  const { id } = useParams();

  // always fetch student when page loads - this prevents stale data
  useEffect(() => {
    dispatch(studentFetchRequested(id));
  }, []);

  return studentNotFound ? (
    <Error404 />
  ) : (
    student && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'Students', to: '/students', key: 'students' },
            { text: student.full_name, key: 'edit-student' },
          ]}
        />
        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <StudentDetails student={student} />,
            },
            {
              label: 'Edit',
              icon: <EditIcon />,
              contents: (
                <StudentForm
                  disabled={savingStudent}
                  initialStudent={student}
                />
              ),
            },
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={student.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default Student;
