import {
  EXTERNAL_SBAT_SAVE_REQUESTED,
  EXTERNAL_SBAT_SAVE_SUCCEEDED,
  EXTERNAL_SBAT_FETCH_REQUESTED,
  EXTERNAL_SBAT_FETCH_SUCCEEDED,
  EXTERNAL_SBAT_NOT_FOUND,
} from '../actionTypes';

const initialState = {
  sbat: null,
  user: null,
  fetchingSbat: false,
  savingSbat: false,
  sbatNotFound: false,
};

const externalReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTERNAL_SBAT_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSbat: true,
        sbat: null,
        user: null,
        sbatNotFound: false,
        sbatCanEdit: false,
      };
    }

    case EXTERNAL_SBAT_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSbat: false,
        user: action.user,
        sbat: action.sbat,
      };
    }

    case EXTERNAL_SBAT_NOT_FOUND: {
      return {
        ...state,
        fetchingSbat: false,
        sbatNotFound: true,
      };
    }

    case EXTERNAL_SBAT_SAVE_REQUESTED: {
      return {
        ...state,
        savingSbat: true,
      };
    }
    case EXTERNAL_SBAT_SAVE_SUCCEEDED: {
      return {
        ...state,
        sbat: null,
        savingSbat: false,
      };
    }

    default:
      return state;
  }
};

export default externalReducer;
