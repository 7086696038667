import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import {
  userNotify,
  systemUserLoginSucceeded,
  systemUserLoginFailed,
} from '../actions';
import { SYSTEM_USER_LOGIN_REQUESTED } from '../auth/actionTypes';

function* loginSystemUser(action) {
  try {
    const user = yield call(
      api.loginSystemUser,
      action.username,
      action.password
    );
    yield put(systemUserLoginSucceeded(user));
    yield put(userNotify('Logged in successfully'));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(systemUserLoginFailed());
      yield put(userNotify('Log in failed', false));
      return;
    }

    throw e;
  }
}

function* loginSystemUserSaga() {
  yield takeLatest(SYSTEM_USER_LOGIN_REQUESTED, loginSystemUser);
}

export default loginSystemUserSaga;
