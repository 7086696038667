import {
  STUDENTS_FETCH_REQUESTED,
  STUDENTS_FETCH_SUCCEEDED,
  STUDENT_FETCH_REQUESTED,
  STUDENT_FETCH_SUCCEEDED,
  STUDENT_NOT_FOUND,
  STUDENT_SAVE_REQUESTED,
  STUDENT_SAVE_SUCCEEDED,
  STUDENT_SAVE_FAILED,
  UPDATE_STUDENTS_QUERY_SORT,
  UPDATE_STUDENTS_QUERY_SEARCH,
  UPDATE_STUDENTS_QUERY_FILTER,
  RESET_STUDENTS_QUERY,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  students: null,
  fetchingStudents: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'last_name',
      asc: true,
    },
  },
  student: null,
  fetchingStudent: false,
  savingStudent: false,
  saveErrors: [],
  studentNotFound: false,
};

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENTS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingStudents: true,
      };
    }

    case STUDENTS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingStudents: false,
        students:
          state.students === null
            ? action.students
            : [...state.students, ...action.students], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_STUDENTS_QUERY_SORT: {
      return {
        ...state,
        fetchingStudents: false,
        students: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_STUDENTS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingStudents: false,
        students: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case UPDATE_STUDENTS_QUERY_FILTER: {
      return {
        ...state,
        fetchingStudents: false,
        students: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {
            ...state.query.filters,
            [action.filterId]: action.optionValue,
          },
        },
      };
    }

    case RESET_STUDENTS_QUERY: {
      return {
        ...state,
        fetchingStudents: false,
        students: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case STUDENT_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingStudent: true,
        student: null,
        studentNotFound: false,
      };
    }

    case STUDENT_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingStudent: false,
        student: action.student,
      };
    }

    case STUDENT_NOT_FOUND: {
      return {
        ...state,
        fetchingStudent: false,
        studentNotFound: true,
      };
    }

    case STUDENT_SAVE_REQUESTED: {
      return {
        ...state,
        savingStudent: true,
      };
    }

    case STUDENT_SAVE_SUCCEEDED: {
      // Need to reload all students if saved
      return {
        ...state,
        students: null,
        student: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingStudent: false,
        saveErrors: [],
      };
    }

    case STUDENT_SAVE_FAILED: {
      return {
        ...state,
        saveErrors: action.errors ?? [],
        savingStudent: false,
      };
    }

    case AUTH_USER_PROFILE_SAVE_SUCCEEDED: {
      return {
        ...state,
        students: null,
        query: {
          ...state.query,
          cursor: null,
        },
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default studentsReducer;
