import React from 'react';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SettingsIcon from '@material-ui/icons/Settings';

import Breadcrumbs from '../Breadcrumbs';
import TabSections from '../TabSections';
import ProfileDetails from './ProfileDetails';
import ProfileSettings from './ProfileSettings';

const Profile = () => {
  return (
    <>
      <Breadcrumbs crumbs={[{ text: 'Profile', key: 'profile' }]} />
      <TabSections
        sections={[
          {
            label: 'Details',
            icon: <PermIdentityIcon />,
            contents: <ProfileDetails />,
          },
          {
            label: 'Settings',
            icon: <SettingsIcon />,
            contents: <ProfileSettings />,
          },
        ]}
      />
    </>
  );
};

export default Profile;
