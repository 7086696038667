import axios from 'axios';
//import queryString from 'query-string';
import lzString from 'lz-string';
import config from './config';

axios.defaults.withCredentials = true;

const getAuthUserRoute = `${config.apiBaseUrl}/auth/user`;
const postImpersonateUserRoute = `${config.apiBaseUrl}/auth/impersonate`;
const postAuthUserProfileRoute = `${config.apiBaseUrl}/auth/profile`;
const getApiConfigRoute = `${config.apiBaseUrl}/config`;
const getUsersRoute = `${config.apiBaseUrl}/users`;
const postEmployerRoute = `${config.apiBaseUrl}/employers`;
const postNewEmployerRoute = `${config.apiBaseUrl}/employers/add`;
const getPendingUsersRoute = `${config.apiBaseUrl}/users/pending`;
const getPendingSbatsRoute = `${config.apiBaseUrl}/sbats/pending`;
const getEmployersRoute = `${config.apiBaseUrl}/employers`;
const getSchoolsRoute = `${config.apiBaseUrl}/schools`;
const postSchoolRoute = `${config.apiBaseUrl}/schools`;
const getStudentsRoute = `${config.apiBaseUrl}/students`;
const postStudentRoute = `${config.apiBaseUrl}/students`;
const postNewStudentRoute = `${config.apiBaseUrl}/students/add`;
const getContactsRoute = `${config.apiBaseUrl}/contacts`;
const postContactRoute = `${config.apiBaseUrl}/contacts`;
const getSbatsRoute = `${config.apiBaseUrl}/sbats`;
const postSbatRoute = `${config.apiBaseUrl}/sbats`;
const postNewSbatRoute = `${config.apiBaseUrl}/sbats/add`;
const getExternalSbatRoute = `${config.apiBaseUrl}/external/sbats`;
const postExternalSbatRoute = `${config.apiBaseUrl}/external/sbats`;
const postLogoutUserRoute = `${config.apiBaseUrl}/auth/logout`;
const postLoginSystemUserRoute = `${config.apiBaseUrl}/auth/system/login`;
const registerUserRoute = `${config.apiBaseUrl}/auth/register`;
const getTrainingPlanOptionsRoute = `${config.apiBaseUrl}/sbats/training-plan-options`;
const getQualificationsRoute = `${config.apiBaseUrl}/qualifications`;
const postQualificationRoute = `${config.apiBaseUrl}/qualifications`;
const postNewQualificationRoute = `${config.apiBaseUrl}/qualifications/add`;

const api = {
  fetchAuthUser: async () => {
    const res = await axios.get(getAuthUserRoute);
    return res.data.data;
  },
  fetchApiConfig: async () => {
    const res = await axios.get(getApiConfigRoute);
    return res.data;
  },
  fetchUsers: async (query) => {
    const res = await axios.get(getUsersRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchUser: async (userId) => {
    const res = await axios.get(`${getUsersRoute}/${userId}`);
    return res.data.data;
  },
  logoutUser: async () => {
    return axios.post(postLogoutUserRoute);
  },
  loginSystemUser: async (username, password) => {
    const res = await axios.post(postLoginSystemUserRoute, {
      username,
      password,
    });
    return res.data.data;
  },
  impersonateUser: async (userId) => {
    const res = await axios.post(`${postImpersonateUserRoute}/${userId}`, {});
    return res.data.data;
  },
  fetchEmployers: async (query) => {
    const res = await axios.get(getEmployersRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchEmployer: async (employerId) => {
    const res = await axios.get(`${getEmployersRoute}/${employerId}`);
    return res.data.data;
  },
  createEmployer: async (data) => {
    const res = await axios.post(`${postNewEmployerRoute}`, data);
    return res.data.data;
  },
  saveEmployer: async (employerId, data) => {
    const res = await axios.post(`${postEmployerRoute}/${employerId}`, data);
    return res.data.data;
  },
  fetchSchools: async (query) => {
    const res = await axios.get(getSchoolsRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchSchool: async (schoolId) => {
    const res = await axios.get(`${getSchoolsRoute}/${schoolId}`);
    return res.data.data;
  },
  saveSchool: async (schoolId, data) => {
    const res = await axios.post(`${postSchoolRoute}/${schoolId}`, data);
    return res.data.data;
  },
  fetchStudents: async (query) => {
    const res = await axios.get(getStudentsRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchStudent: async (studentId) => {
    const res = await axios.get(`${getStudentsRoute}/${studentId}`);
    return res.data.data;
  },
  createStudent: async (data) => {
    const res = await axios.post(`${postNewStudentRoute}`, data);
    return res.data.data;
  },
  saveStudent: async (studentId, data) => {
    const res = await axios.post(`${postStudentRoute}/${studentId}`, data);
    return res.data.data;
  },
  fetchContacts: async (query) => {
    const res = await axios.get(getContactsRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  suggestContacts: async (q) => {
    const res = await axios.get(`${getContactsRoute}/suggest/${q}`);
    return res.data.data;
  },
  fetchContact: async (contactId) => {
    const res = await axios.get(`${getContactsRoute}/${contactId}`);
    return res.data.data;
  },
  saveContact: async (contactId, data) => {
    const res = await axios.post(`${postContactRoute}/${contactId}`, data);
    return res.data.data;
  },
  fetchSbats: async (query) => {
    const res = await axios.get(getSbatsRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchSbat: async (sbatId) => {
    const res = await axios.get(`${getSbatsRoute}/${sbatId}`);
    return res.data.data;
  },
  createSbat: async (data) => {
    const res = await axios.post(`${postNewSbatRoute}`, data);
    return res.data;
  },
  saveSbat: async (sbatId, data) => {
    const res = await axios.post(`${postSbatRoute}/${sbatId}`, data);
    return res.data;
  },
  submitSbat: async (sbatId, data) => {
    const res = await axios.post(`${postSbatRoute}/${sbatId}/submit`, data);
    return res.data;
  },
  adminSbat: async (sbatId, data) => {
    const res = await axios.post(`${postSbatRoute}/${sbatId}/admin`, data);
    return res.data;
  },
  registerUser: async (data) => {
    const res = await axios.post(`${registerUserRoute}`, data);
    return res.data.data;
  },
  saveUser: async (userId, data) => {
    const res = await axios.post(`${getUsersRoute}/${userId}`, data);
    return res.data.data;
  },
  fetchPendingUsers: async () => {
    const res = await axios.get(`${getPendingUsersRoute}`);
    return res.data.data;
  },
  saveUserProfile: async (data) => {
    const res = await axios.post(`${postAuthUserProfileRoute}`, data);
    return res.data.data;
  },
  fetchPendingSbats: async () => {
    const res = await axios.get(`${getPendingSbatsRoute}`);
    return res.data.data;
  },
  fetchTrainingPlanOptions: async () => {
    const res = await axios.get(`${getTrainingPlanOptionsRoute}`);
    return res.data;
  },
  uploadTrainingPlan: async (id, file) => {
    const formData = new FormData();
    formData.append('trainingPlan', file);
    const res = await axios.post(
      `${postSbatRoute}/${id}/training-plan`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    return res;
  },

  // External user api calls
  fetchExternalSbat: async (sbatId, token) => {
    const res = await axios.get(
      `${getExternalSbatRoute}/${sbatId}?token=${token}`
    );
    return res.data;
  },
  saveExternalSbat: async (sbatId, data, token) => {
    const res = await axios.post(
      `${postExternalSbatRoute}/${sbatId}?token=${token}`,
      data
    );
    return res.data.data;
  },
  submitExternalSbat: async (sbatId, data, token) => {
    const res = await axios.post(
      `${postExternalSbatRoute}/${sbatId}/submit?token=${token}`,
      data
    );
    return res.data.data;
  },

  uploadExternalTrainingPlan: async (id, file, token) => {
    const formData = new FormData();
    formData.append('trainingPlan', file);
    const res = await axios.post(
      `${postExternalSbatRoute}/${id}/training-plan?token=${token}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    return res;
  },
  suggestStudents: async (schoolId, q) => {
    const res = await axios.get(
      `${getSchoolsRoute}/${schoolId}/suggest-students/${q}`
    );
    return res.data.data;
  },
  fetchQualifications: async (query) => {
    const res = await axios.get(getQualificationsRoute, {
      params: {
        query: lzString.compressToEncodedURIComponent(JSON.stringify(query)),
      },
    });
    return res.data;
  },
  fetchQualification: async (qualificationId) => {
    const res = await axios.get(`${getQualificationsRoute}/${qualificationId}`);
    return res.data.data;
  },
  createQualification: async (data) => {
    const res = await axios.post(`${postNewQualificationRoute}`, data);
    return res.data.data;
  },
  saveQualification: async (qualificationId, data) => {
    const res = await axios.post(`${postQualificationRoute}/${qualificationId}`, data);
    return res.data.data;
  },
  suggestQualifications: async (q) => {
    const res = await axios.get(`${getQualificationsRoute}/suggest/${q}`);
    return res.data.data;
  },
};

export default api;
