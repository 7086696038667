import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ui from './ui/reducer';
import auth from './auth/reducer';
import errors from './errors/reducer';
import employers from './employers/reducer';
import sbats from './sbats/reducer';
import external from './external/reducer';
import schools from './schools/reducer';
import students from './students/reducer';
import contacts from './contacts/reducer';
import users from './users/reducer';
import apiConfig from './apiConfig/reducer';
import qualifications from './qualifications/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    ui,
    errors,
    employers,
    sbats,
    external,
    schools,
    students,
    contacts,
    users,
    apiConfig,
    qualifications,
  });

export default createRootReducer;
