import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Form from '../form/Form';
import TextInput from '../form/TextInput';
import SelectInput from '../form/SelectInput';
import CheckboxInput from '../form/CheckboxInput';
import useStates from '../../hooks/useStates';
import getValidationSchema, {
  stringValidation,
  abnValidation,
  idSelectionValidation,
  postcodeValidation,
} from '../form/ValidationSchema';
import { employerSaveRequested } from '../../redux/actions';
import useAuthUser from '../../hooks/useAuthUser';

const EmployerForm = ({ disabled, initialEmployer }) => {
  const schema = {
    trading_name: {
      validation: stringValidation,
      required: true,
    },
    legal_name: {
      validation: stringValidation,
      required: true,
    },
    abn: {
      validation: abnValidation,
      required: true,
    },
    street_address: {
      validation: stringValidation,
      required: true,
    },
    suburb: {
      validation: stringValidation,
      required: true,
    },
    state_id: {
      validation: idSelectionValidation,
      required: true,
    },
    postcode: {
      validation: postcodeValidation,
      required: true,
    },
  };

  const dispatch = useDispatch();
  const { control, register, handleSubmit, errors } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
    },
  });

  const { states } = useStates();

  const { authUserIsSchool } = useAuthUser();

  const saveErrors = useSelector((state) => state.employers.saveErrors);

  const onSubmit = (data) => {
    dispatch(
      employerSaveRequested({
        id: initialEmployer.id,
        data,
      })
    );
  };

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            name="trading_name"
            label="Trading Name"
            defaultValue={initialEmployer.trading_name || ''}
            control={control}
            hasError={Boolean(errors.trading_name || saveErrors.trading_name)}
            errorText={errors?.trading_name?.message ?? saveErrors?.trading_name?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            name="legal_name"
            label="Legal Name"
            defaultValue={initialEmployer.legal_name}
            control={control}
            hasError={Boolean(errors.legal_name || saveErrors.legal_name)}
            errorText={errors?.legal_name?.message ?? saveErrors?.legal_name?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            name="abn"
            label="ABN"
            defaultValue={initialEmployer.abn || ''}
            control={control}
            hasError={Boolean(errors.abn || saveErrors.abn)}
            errorText={errors?.abn?.message ?? saveErrors?.abn?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            name="street_address"
            label="Street Address"
            defaultValue={initialEmployer.street_address || ''}
            control={control}
            hasError={Boolean(errors.street_address || saveErrors.street_address)}
            errorText={errors?.street_address?.message ?? saveErrors?.street_address?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextInput
            name="suburb"
            label="Suburb"
            defaultValue={initialEmployer.suburb || ''}
            control={control}
            hasError={Boolean(errors.suburb || saveErrors.suburb)}
            errorText={errors?.suburb?.message ?? saveErrors?.suburb?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="State"
            name="state_id"
            options={states}
            control={control}
            defaultValue={initialEmployer.state_id || ''}
            hasError={Boolean(errors.state_id || saveErrors.state_id)}
            errorText={errors?.state_id?.message ?? saveErrors?.state_id?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            name="postcode"
            label="Postcode"
            defaultValue={initialEmployer.postcode || ''}
            control={control}
            hasError={Boolean(errors.postcode || saveErrors.postcode)}
            errorText={errors?.postcode?.message ?? saveErrors?.postcode?.message}
          />
        </Grid>
        {!authUserIsSchool && (
        <Grid item xs={12} lg={4}>
          <CheckboxInput
            register={register}
            name="duty_of_care"
            label="Duty of care"
            defaultValue={initialEmployer.duty_of_care}
          />
        </Grid>
        )}
       </Grid>
    </Form>
  );
};

export default EmployerForm;
