import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { employersFetchSucceeded } from '../actions';
import { EMPLOYERS_FETCH_REQUESTED } from '../actionTypes';

function* fetchEmployers(action) {
  const res = yield call(api.fetchEmployers, action.query);
  yield put(employersFetchSucceeded(res));
}

function* fetchEmployersSaga() {
  yield takeLatest(EMPLOYERS_FETCH_REQUESTED, fetchEmployers);
}

export default fetchEmployersSaga;
