import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setUsersQuery } from '../actions';
import { REDIRECT_TO_USERS_WITH_QUERY } from '../actionTypes';

function* redirectToUsersWithQuery({ query }) {
  yield all([put(setUsersQuery(query)), put(push('/users'))]);
}

function* redirectToUsersWithQuerySaga() {
  yield takeLatest(REDIRECT_TO_USERS_WITH_QUERY, redirectToUsersWithQuery);
}

export default redirectToUsersWithQuerySaga;
