export const CONTACTS_FETCH_REQUESTED = 'CONTACTS_FETCH_REQUESTED';
export const CONTACTS_FETCH_SUCCEEDED = 'CONTACTS_FETCH_SUCCEEDED';
export const UPDATE_CONTACTS_QUERY_SORT = 'UPDATE_CONTACTS_QUERY_SORT';
export const UPDATE_CONTACTS_QUERY_SEARCH = 'UPDATE_CONTACTS_QUERY_SEARCH';
export const RESET_CONTACTS_QUERY = 'RESET_CONTACTS_QUERY';

export const CONTACT_FETCH_REQUESTED = 'CONTACT_FETCH_REQUESTED';
export const CONTACT_FETCH_SUCCEEDED = 'CONTACT_FETCH_SUCCEEDED';
export const CONTACT_NOT_FOUND = 'CONTACT_NOT_FOUND';

export const CONTACT_SAVE_REQUESTED = 'CONTACT_SAVE_REQUESTED';
export const CONTACT_SAVE_SUCCEEDED = 'CONTACT_SAVE_SUCCEEDED';
export const CONTACT_SAVE_FAILED = 'CONTACT_SAVE_FAILED';
