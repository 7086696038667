import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { externalSbatSaveSucceeded, userNotify } from '../actions';
import { EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED } from '../actionTypes';

function* uploadExternalTrainingPlan({ id, file, token }) {
  yield call(api.uploadExternalTrainingPlan, id, file, token);
  yield put(externalSbatSaveSucceeded());
  yield put(userNotify('Training plan updated successfully'));
}

function* uploadExternalTrainingPlanSaga() {
  yield takeLatest(
    EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED,
    uploadExternalTrainingPlan
  );
}

export default uploadExternalTrainingPlanSaga;
