import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { apiConfigFetchSucceeded } from '../actions';
import { API_CONFIG_FETCH_REQUESTED } from '../actionTypes';

function* fetchApiConfig() {
  const apiConfig = yield call(api.fetchApiConfig);
  yield put(apiConfigFetchSucceeded(apiConfig));
}

function* fetchAuthUserSaga() {
  yield takeLatest(API_CONFIG_FETCH_REQUESTED, fetchApiConfig);
}

export default fetchAuthUserSaga;
