import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { externalSbatSaveRequested } from '../../../redux/actions';
import getTrainingPlanValidationSchema,
{
  getTrainingPlanRequiredOnSubmit
} from '../trainingPlan/trainingPlanValidationSchema';
import EmployerDetails from '../trainingPlan/sections/EmployerDetails';
import AnpDetails from '../trainingPlan/sections/AnpDetails';
import useTrainingPlanOptions from '../../../hooks/useTrainingPlanOptions';
import { SBAT_ACTION_SAVE, SBAT_ACTION_SUBMIT } from '../../../sbatActions';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  element: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginRight: theme.spacing(2),
  },
}));

const TrainingPlanForm = ({ initialSbat, token }) => {
  const classes = useStyles();
  const { trainingPlanOptions } = useTrainingPlanOptions();

  const [saveWithoutValidation, setSaveWithoutValidation] = useState(false);

  const requiredOnSubmit = getTrainingPlanRequiredOnSubmit(
    initialSbat.status.id,
    -1
  );

  const { control, handleSubmit, errors, watch, setValue, getValues, register } = useForm({
    validationSchema: getTrainingPlanValidationSchema(
      initialSbat.status.id,
      -1
    ),
    validationContext: { save: saveWithoutValidation },
    reValidateMode: 'onSubmit',
  });
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.external.savingSbat);

  const onSave = (data) => {
    dispatch(
      externalSbatSaveRequested({
        id: initialSbat.id,
        data: {
          ...data,
        },
        action: SBAT_ACTION_SAVE,

        token,
      })
    );
  };

  useEffect(() => {
    if (saveWithoutValidation) {
      if (!savingSbat) {
        handleSubmit(onSave)();
      }
      setSaveWithoutValidation(false);
    }
  }, [saveWithoutValidation, savingSbat]);

  const onSubmit = (data) => {
    dispatch(
      externalSbatSaveRequested({
        id: initialSbat.id,
        data: {
          ...data,
        },
        action: SBAT_ACTION_SUBMIT,
        token,
      })
    );
  };

  return (
    trainingPlanOptions && (
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <EmployerDetails
          initialSbat={initialSbat}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          control={control}
          errors={errors}
          register={register}
          trainingPlanOptions={trainingPlanOptions}
          enabled
          disableContactLookup
          requiredOnSubmit={requiredOnSubmit}
          />
        <AnpDetails
          initialSbat={initialSbat}
          watch={watch}
          setValue={setValue}
          control={control}
          errors={errors}
          register={register}
          trainingPlanOptions={trainingPlanOptions}
          enabled
          disableContactLookup
          requiredOnSubmit={requiredOnSubmit}
        />
        <Box className={classes.updateButtonBox}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={savingSbat}
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              setSaveWithoutValidation(true);
            }}
          >
            Save
          </Button>
          <Button
            type="submit"
            name="validate"
            variant="contained"
            color="secondary"
            disabled={savingSbat}
            className={classes.submit}
          >
            Progress
          </Button>
        </Box>
      </form>
    )
  );
};

export default TrainingPlanForm;
