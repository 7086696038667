import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Form from '../form/Form';
import AutoCompleteInput from '../form/AutoCompleteInput';
import TextInput from '../form/TextInput';
import DateInput from '../form/DateInput';
import CheckboxInput from '../form/CheckboxInput';
import SelectInput from '../form/SelectInput';
import useSchools from '../../hooks/useSchools';
import useStudentGenders from '../../hooks/useStudentGenders';
import useStates from '../../hooks/useStates';
import getValidationSchema, {
  stringValidation,
  dateValidation,
  idSelectionValidation,
  phoneNumberValidation,
  postcodeValidation,
  emailValidation,
  booleanValidation,
  objectValidation,
  schoolYearValidation,
  ernNumberValidation,
  nesaNumberValidation,
} from '../form/ValidationSchema';
import { studentSaveRequested } from '../../redux/actions';

const StudentForm = ({ disabled, initialStudent }) => {
  const schema = {
    school: {
      validation: objectValidation,
      required: true,
    },
    school_year: {
      validation: schoolYearValidation,
      required: true,
    },
    student_number: {
      validation: nesaNumberValidation,
      required: true,
    },
    student_ern_number: {
      validation: ernNumberValidation,
      required: false,
    },
    first_name: {
      validation: stringValidation,
      required: true,
    },
    last_name: {
      validation: stringValidation,
      required: true,
    },
    date_of_birth: {
      validation: dateValidation,
      required: true,
    },
    gender_id: {
      validation: idSelectionValidation,
      required: true,
    },
    street_address: {
      validation: stringValidation,
      required: true,
    },
    suburb: {
      validation: stringValidation,
      required: true,
    },
    state_id: {
      validation: idSelectionValidation,
      required: true,
    },
    postcode: {
      validation: postcodeValidation,
      required: true,
    },
    phone: {
      validation: phoneNumberValidation,
      required: true,
    },
    mobile: {
      validation: phoneNumberValidation,
      required: true,
    },
    email: {
      validation: emailValidation,
      required: true,
    },
    aboriginal_or_torres: {
      validation: booleanValidation,
      required: true,
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, errors, register } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
      date_of_birth: initialStudent.date_of_birth
        ? DateTime.fromISO(initialStudent.date_of_birth)
        : null,
    },
  });

  const { schoolsUserCanView } = useSchools();

  const defaultSchool = initialStudent.id
    ? schoolsUserCanView.find((school) => {
        return school.id === initialStudent?.school?.id;
      })
    : (schoolsUserCanView?.length === 1 && schoolsUserCanView[0]) || null;

  const { genders } = useStudentGenders();
  const { states } = useStates();

  const saveErrors = useSelector((state) => state.students.saveErrors);

  const onSubmit = (data) => {
    dispatch(
      studentSaveRequested({
        id: initialStudent.id,
        data: {
          ...data,
          school: undefined,
          school_id: data?.school?.id ?? null,
        },
      })
    );
  };

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <AutoCompleteInput
            options={schoolsUserCanView}
            label="Select a school"
            key="school"
            name="school"
            control={control}
            defaultValue={defaultSchool}
            disabled={disabled || schoolsUserCanView.length === 1}
            hasError={Boolean(errors.school || saveErrors.school_id)}
            errorText={errors?.school?.message ?? saveErrors?.school_id?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="School year"
            name="school_year"
            defaultValue={initialStudent?.school_year ?? ''}
            control={control}
            hasError={Boolean(errors.school_year || saveErrors.school_year)}
            errorText={errors?.school_year?.message ?? saveErrors?.school_year?.message}
          />
        </Grid>
       </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Student NESA number"
            name="student_number"
            defaultValue={initialStudent?.student_number ?? ''}
            control={control}
            hasError={Boolean(errors.student_number || saveErrors.student_number)}
            errorText={errors?.student_number?.message ?? saveErrors?.student_number?.message}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Student ERN number"
            name="student_ern_number"
            defaultValue={initialStudent?.student_ern_number ?? ''}
            control={control}
            hasError={Boolean(errors.student_ern_number || saveErrors.student_ern_number)}
            errorText={errors?.student_ern_number?.message ?? saveErrors?.student_ern_number?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography color="secondary">
            Student name used must be student's official FULL name (matching birth certificate)
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="First Name"
            name="first_name"
            defaultValue={initialStudent?.name?.first ?? ''}
            control={control}
            hasError={Boolean(errors.first_name || saveErrors.first_name)}
            errorText={errors?.first_name?.message ?? saveErrors?.first_name?.message}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Last Name"
            name="last_name"
            defaultValue={initialStudent?.name?.last ?? ''}
            control={control}
            hasError={Boolean(errors.last_name || saveErrors.last_name)}
            errorText={errors?.last_name?.message ?? saveErrors?.last_name?.message}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <DateInput
            label="Date of Birth"
            name="date_of_birth"
            defaultValue={initialStudent.date_of_birth ? DateTime.fromISO(initialStudent.date_of_birth) : ''}
            control={control}
            maxDate={new Date()}
            hasError={Boolean(errors.date_of_birth || saveErrors.date_of_birth)}
            errorText={errors?.date_of_birth?.message ?? saveErrors?.date_of_birth?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="Gender"
            name="gender_id"
            options={genders}
            control={control}
            defaultValue={initialStudent?.gender_id ?? ''}
            hasError={Boolean(errors.gender_id || saveErrors.gender_id)}
            errorText={errors?.gender_id?.message ?? saveErrors?.gender_id?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CheckboxInput
            register={register}
            defaultValue={initialStudent?.aboriginal_or_torres}
            name="aboriginal_or_torres"
            label="Aboriginal or Torres Strait Islander origin"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Street Address"
            name="street_address"
            defaultValue={initialStudent?.street_address ?? ''}
            control={control}
            hasError={Boolean(errors.street_address || saveErrors.street_address)}
            errorText={errors?.street_address?.message ?? saveErrors?.street_address?.message}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Suburb"
            name="suburb"
            defaultValue={initialStudent?.suburb ?? ''}
            control={control}
            hasError={Boolean(errors.suburb || saveErrors.suburb)}
            errorText={errors?.suburb?.message ?? saveErrors?.suburb?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="State"
            name="state_id"
            options={states}
            control={control}
            defaultValue={initialStudent?.state_id ?? ''}
            hasError={Boolean(errors.state_id || saveErrors.state_id)}
            errorText={errors?.state_id?.message ?? saveErrors?.state_id?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Postcode"
            name="postcode"
            defaultValue={initialStudent?.postcode ?? ''}
            control={control}
            hasError={Boolean(errors.postcode || saveErrors.postcode)}
            errorText={errors?.postcode?.message ?? saveErrors?.postcode?.message}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Phone"
            name="phone"
            defaultValue={initialStudent?.phone ?? ''}
            control={control}
            hasError={Boolean(errors.phone || saveErrors.phone)}
            errorText={errors?.phone?.message ?? saveErrors?.phone?.message}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextInput
            label="Mobile"
            name="mobile"
            defaultValue={initialStudent?.mobile ?? ''}
            control={control}
            hasError={Boolean(errors.mobile || saveErrors.mobile)}
            errorText={errors?.mobile?.message ?? saveErrors?.mobile?.message}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Email"
            name="email"
            defaultValue={initialStudent?.email ?? ''}
            control={control}
            hasError={Boolean(errors.email || saveErrors.email)}
            errorText={errors?.email?.message ?? saveErrors?.email?.message}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default StudentForm;
