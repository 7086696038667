import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import config from './config';
import PrivateRoute from './components/PrivateRoute';
import Error404 from './components/errors/Error404';
import Employers from './components/employers/Employers';
import AddEmployer from './components/employers/AddEmployer';
import Employer from './components/employers/Employer';
import Schools from './components/schools/Schools';
import School from './components/schools/School';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Page from './components/page/Page';
import Profile from './components/profile/Profile';
import Users from './components/users/Users';
import User from './components/users/User';
import Sbats from './components/sbats/Sbats';
import AddSbat from './components/sbats/AddSbat';
import Sbat from './components/sbats/Sbat';
import ExternalSbat from './components/sbats/external/Sbat';
import SystemLogin from './components/login/SystemLogin';
import Register from './components/register/Register';
import Pending from './components/register/Pending';
import useAuthUser from './hooks/useAuthUser';
import Contacts from './components/contacts/Contacts';
import Contact from './components/contacts/Contact';
import Students from './components/students/Students';
import AddStudent from './components/students/AddStudent';
import Student from './components/students/Student';
import Qualifications from './components/qualifications/Qualifications';
import Qualification from './components/qualifications/Qualification';
import AddQualification from './components/qualifications/AddQualification';

import RedirectIf from './components/RedirectIf';

import {
  authUserFetchRequested,
  apiConfigFetchRequested,
} from './redux/actions';
import UnexpectedError from './components/errors/UnexpectedError';

const App = () => {
  document.title = config.name;
  const dispatch = useDispatch();

  const unexpectedError = useSelector((state) => state.errors.unexpectedError);
  const checkedAuth = useSelector((state) => state.auth.checkedAuth);
  const checkingAuth = useSelector((state) => state.auth.checkingAuth);
  const { authUserIsAuthenticated } = useAuthUser();
  const apiConfig = useSelector((state) => state.apiConfig.config);
  const fetchingApiConfig = useSelector(
    (state) => state.apiConfig.fetchingApiConfig
  );

  if (!checkedAuth && !checkingAuth) {
    dispatch(authUserFetchRequested());
  }

  if (authUserIsAuthenticated && !apiConfig && !fetchingApiConfig) {
    dispatch(apiConfigFetchRequested());
  }

  return unexpectedError ? (
    <UnexpectedError />
  ) : (
    ((authUserIsAuthenticated && apiConfig) ||
      (!authUserIsAuthenticated && checkedAuth)) && (
      <Page>
        <Switch>
          <PrivateRoute
            exact
            resource="schools"
            ability="viewAny"
            path="/schools"
            component={Schools}
          />
          <PrivateRoute
            resource="schools"
            ability="edit"
            exact
            path="/schools/:id"
            component={School}
          />
          <PrivateRoute
            resource="users"
            ability="viewSelf"
            exact
            path="/"
            component={Dashboard}
          />
          <PrivateRoute
            resource="users"
            ability="registerSelf"
            exact
            path="/register"
            component={Register}
          />
          <PrivateRoute
            resource="users"
            ability="viewPending"
            exact
            path="/pending"
            component={Pending}
          />
          <PrivateRoute
            resource="users"
            ability="viewSelf"
            exact
            path="/profile"
            component={Profile}
          />
          <PrivateRoute
            resource="sbats"
            ability="viewAny"
            exact
            path="/sbats"
            component={Sbats}
          />
          <PrivateRoute
            resource="sbats"
            ability="add"
            exact
            path="/sbats/add"
            component={AddSbat}
          />
          <PrivateRoute
            resource="sbats"
            ability="edit"
            exact
            path="/sbats/:id"
            component={Sbat}
          />
          <PrivateRoute
            resource="employers"
            ability="viewAny"
            exact
            path="/employers"
            component={Employers}
          />
          <PrivateRoute
            resource="employers"
            ability="add"
            exact
            path="/employers/add"
            component={AddEmployer}
          />
          <PrivateRoute
            resource="employers"
            ability="edit"
            exact
            path="/employers/:id"
            component={Employer}
          />
          <PrivateRoute
            resource="contacts"
            ability="viewAny"
            exact
            path="/contacts"
            component={Contacts}
          />
          <PrivateRoute
            resource="contacts"
            ability="edit"
            exact
            path="/contacts/:id"
            component={Contact}
          />
          <PrivateRoute
            resource="users"
            ability="viewAny"
            exact
            path="/users"
            component={Users}
          />
          <PrivateRoute
            resource="users"
            ability="edit"
            exact
            path="/users/:id"
            component={User}
          />
          <PrivateRoute
            exact
            resource="students"
            ability="viewAny"
            path="/students"
            component={Students}
          />
          <PrivateRoute
            resource="students"
            ability="add"
            exact
            path="/students/add"
            component={AddStudent}
          />
          <PrivateRoute
            resource="students"
            ability="edit"
            exact
            path="/students/:id"
            component={Student}
          />
          <PrivateRoute
            exact
            resource="qualifications"
            ability="viewAny"
            path="/qualifications"
            component={Qualifications}
          />
          <PrivateRoute
            resource="qualifications"
            ability="add"
            exact
            path="/qualifications/add"
            component={AddQualification}
          />
          <PrivateRoute
            resource="qualifications"
            ability="edit"
            exact
            path="/qualifications/:id"
            component={Qualification}
          />
          <Route exact path="/external/sbats/:id" component={ExternalSbat} />
          <Route exact path="/system/login" component={SystemLogin} />
          <RedirectIf condition={authUserIsAuthenticated} to="/">
            <Route exact path="/login" component={Login} />
          </RedirectIf>
          <Route component={Error404} />
        </Switch>
      </Page>
    )
  );
};

export default App;
