import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { pendingUsersFetchSucceeded } from '../actions';
import { PENDING_USERS_FETCH_REQUESTED } from '../actionTypes';

function* fetchPendingUsers() {
  const res = yield call(api.fetchPendingUsers);
  yield put(pendingUsersFetchSucceeded(res));
}

function* fetchPendingUsersSaga() {
  yield takeLatest(PENDING_USERS_FETCH_REQUESTED, fetchPendingUsers);
}

export default fetchPendingUsersSaga;
