import { UNEXPECTED_ERROR_OCCURRED } from './actionTypes';

const initialState = {
  unexpectedError: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNEXPECTED_ERROR_OCCURRED: {
      return {
        ...state,
        unexpectedError: true,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
