import { useSelector } from 'react-redux';

const useRtos = () => {
  const rtos = useSelector((state) => state.apiConfig.config.rtos);

  return {
    rtos,
  };
};

export default useRtos;
