import React from 'react';
import { Route } from 'react-router-dom';

import RedirectIf from './RedirectIf';
import useAuthUser from '../hooks/useAuthUser';
import abilities from '../abilities';
import Error403 from './errors/Error403';

/* eslint-disable react/jsx-props-no-spreading */

const PrivateRoute = ({ ability, resource, component, ...rest }) => {
  const {
    authUserIsAuthenticated,
    authUserHasNoRole,
    authUserHasRole,
    authUserIsPending,
    authUser,
  } = useAuthUser();

  const hasAbility = abilities[resource][ability](authUser);

  return (
    <RedirectIf condition={!authUserIsAuthenticated} to="/login">
      {authUserIsAuthenticated && (
        <RedirectIf condition={authUserHasNoRole} to="/register">
          <RedirectIf
            condition={authUserHasRole && authUserIsPending}
            to="/pending"
          >
            <Route {...rest} component={hasAbility ? component : Error403} />
          </RedirectIf>
        </RedirectIf>
      )}
    </RedirectIf>
  );
};

export default PrivateRoute;
