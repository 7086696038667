import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import {
  userNotify,
  authUserImpersonateSucceeded,
} from '../actions';
import { AUTH_USER_IMPERSONATE_REQUESTED } from '../auth/actionTypes';

function* impersonateUser(action) {
  try {
    const user = yield call(
      api.impersonateUser,
      action.user.id,
    );
    const role = action.user.role.name;
    const name = `${action.user.name.first} ${action.user.name.last}`;
    yield put(userNotify(`Impersonated ${role}: ${name}`));
    yield put(push('/'));
    yield put(authUserImpersonateSucceeded(user));
  } catch (e) {
    if (e.response && e.response.status === 403) {
      yield put(userNotify('Impersonate forbidden', false));
      return;
    }

    throw e;
  }
}

function* impersonateUserSaga() {
  yield takeLatest(AUTH_USER_IMPERSONATE_REQUESTED, impersonateUser);
}

export default impersonateUserSaga;
