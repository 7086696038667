import {
  API_CONFIG_FETCH_REQUESTED,
  API_CONFIG_FETCH_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  fetchingApiConfig: false,
  config: null,
};

const apiConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_CONFIG_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingApiConfig: false,
        config: action.apiConfig,
      };
    }

    case API_CONFIG_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingApiConfig: true,
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default apiConfigReducer;
