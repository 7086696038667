import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Form from '../form/Form';
import TextInput from '../form/TextInput';
import getValidationSchema, {
  stringValidation,
  idValidation,
} from '../form/ValidationSchema';
import { qualificationSaveRequested } from '../../redux/actions';

const QualificationForm = ({ disabled, initialQualification }) => {
  const schema = {
    vto_id: {
      validation: idValidation,
      required: true,
    },
    national_code: {
      validation: stringValidation,
      required: true,
    },
    qualification_title: {
      validation: stringValidation,
      required: true,
    },
    qualification_level: {
      validation: stringValidation,
      required: true,
    },
    vocation_title: {
      validation: stringValidation,
      required: true,
    },
    nesa_qualification_name: {
      validation: stringValidation,
      required: false,
    },
    nesa_stream: {
      validation: idValidation,
      required: false,
    },
    ivet_course_name: {
      validation: stringValidation,
      required: false,
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, errors, register } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
    },
  });

  const saveErrors = useSelector((state) => state.qualifications.saveErrors);

  const onSubmit = (data) => {
    dispatch(
      qualificationSaveRequested({
        id: initialQualification.id,
        data: data,
      })
    );
  };

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="VTO ID"
            name="vto_id"
            defaultValue={initialQualification?.vto_id ?? ''}
            control={control}
            hasError={Boolean(errors.vto_id || saveErrors.vto_id)}
            errorText={errors?.vto_id?.message ?? saveErrors?.vto_id?.message}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="National Code"
            name="national_code"
            defaultValue={initialQualification?.national_code ?? ''}
            control={control}
            hasError={Boolean(errors.national_code || saveErrors.national_code)}
            errorText={errors?.national_code?.message ?? saveErrors?.national_code?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Qualification Title"
            name="qualification_title"
            defaultValue={initialQualification?.qualification_title ?? ''}
            control={control}
            hasError={Boolean(errors.qualification_title || saveErrors.qualification_title)}
            errorText={errors?.qualification_title?.message ?? saveErrors?.qualification_title?.message}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Qualification Level"
            name="qualification_level"
            defaultValue={initialQualification?.qualification_level ?? ''}
            control={control}
            hasError={Boolean(errors.qualification_level || saveErrors.qualification_level)}
            errorText={errors?.qualification_level?.message ?? saveErrors?.qualification_level?.message}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Vocation Title"
            name="vocation_title"
            defaultValue={initialQualification?.vocation_title ?? ''}
            control={control}
            hasError={Boolean(errors.vocation_title || saveErrors.vocation_title)}
            errorText={errors?.vocation_title?.message ?? saveErrors?.vocation_title?.message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <TextInput
            label="NESA Qualification Name"
            name="nesa_qualification_name"
            defaultValue={initialQualification?.nesa_qualification_name ?? ''}
            control={control}
            hasError={Boolean(errors.nesa_qualification_name || saveErrors.nesa_qualification_name)}
            errorText={errors?.nesa_qualification_name?.message ?? saveErrors?.nesa_qualification_name?.message}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextInput
            label="NESA Stream"
            name="nesa_stream"
            defaultValue={initialQualification?.nesa_stream ?? ''}
            control={control}
            hasError={Boolean(errors.nesa_stream || saveErrors.nesa_stream)}
            errorText={errors?.nesa_stream?.message ?? saveErrors?.nesa_stream?.message}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="IVET Course Name"
            name="ivet_course_name"
            defaultValue={initialQualification?.ivet_course_name ?? ''}
            control={control}
            hasError={Boolean(errors.ivet_course_name || saveErrors.ivet_course_name)}
            errorText={errors?.ivet_course_name?.message ?? saveErrors?.ivet_course_name?.message}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default QualificationForm;
