import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import lzString from 'lz-string';

import ResourceTableSearch from './ResourceTableSearch';
import ResourceTableFilters from './ResourceTableFilters';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchBox: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
}));

const ResourceTableToolbar = ({
  updateResourceQuerySearch,
  resourceQuery,
  searchLabel,
  exportResourcesUrl,
  filters,
  updateResourceQueryFilter,
  resetResourceQuery,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const downloadCsvLink = `${exportResourcesUrl}?query=${lzString.compressToEncodedURIComponent(
    JSON.stringify(resourceQuery)
  )}`;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9} lg={10}>
          <Box className={classes.searchBox}>
            <ResourceTableSearch
              className={classes.search}
              searchLabel={searchLabel}
              searchTerm={resourceQuery.search}
              updateResourceQuerySearch={updateResourceQuerySearch}
            />
          </Box>
          <Box>
            {filters && (
              <ResourceTableFilters
                updateResourceQueryFilter={updateResourceQueryFilter}
                selectedOptions={resourceQuery.filters}
                filters={filters}
              />
            )}
          </Box>
        </Grid>
        <Grid className={classes.buttonGrid} item xs={12} sm={3} lg={2}>
          <Button
            className={classes.button}
            onClick={() => {
              dispatch(resetResourceQuery());
            }}
            variant="outlined"
            color="primary"
          >
            Reset filters
          </Button>
          <Button
            href={downloadCsvLink}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResourceTableToolbar;
