import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import { sbatSaveSucceeded, userNotify } from '../actions';
import { SBAT_SAVE_REQUESTED } from '../actionTypes';
import {
  SBAT_ACTION_SAVE,
  SBAT_ACTION_SUBMIT,
  SBAT_ACTION_ADMIN,
} from '../../sbatActions';

function getApiAction(action) {
  switch (action) {
    case SBAT_ACTION_SAVE:
      return api.saveSbat;
    case SBAT_ACTION_SUBMIT:
      return api.submitSbat;
    case SBAT_ACTION_ADMIN:
      return api.adminSbat;
    default:
      return undefined;
  }
}

function* saveSbat({ id, data, action }) {
  const sbatId = id
    ? yield call(getApiAction(action), id, data)
    : yield call(api.createSbat, data);
  // update state and redirect
  yield put(sbatSaveSucceeded());

  yield put(
    userNotify(
      `SBAT ${
        action === SBAT_ACTION_SUBMIT ? 'progressed' : 'saved'
      } successfully`
    )
  );
  if (!id) {
    yield put(push(`/sbats/${sbatId}`));
  }
}

function* saveSbatSaga() {
  yield takeLatest(SBAT_SAVE_REQUESTED, saveSbat);
}

export default saveSbatSaga;
