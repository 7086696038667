import React from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import AutoCompleteListBox from '../form/AutoCompleteListBox';

const AutoCompleteFilter = ({
  filter,
  selectedOptionValue,
  updateResourceQueryFilter,
}) => {
  const dispatch = useDispatch();

  const selectOption = (event, selected) => {
    dispatch(
      updateResourceQueryFilter(filter.id, selected ? selected.value : null)
    );
  };

  const selectedOption = filter.options.find((option) => {
    return option.value === selectedOptionValue;
  });

  return (
    <Autocomplete
      options={filter.options}
      getOptionLabel={(option) => option.label}
      ListboxComponent={AutoCompleteListBox}
      ListboxProps={{ itemSize: 36 }}
      value={selectedOption || null}
      onChange={(e, s) => selectOption(e, s)}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label={filter.label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default AutoCompleteFilter;
