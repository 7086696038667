import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CenteredContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="xs">
      <Paper className={classes.paper}>{children}</Paper>
    </Container>
  );
};

export default CenteredContainer;
