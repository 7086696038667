import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { qualificationsFetchSucceeded } from '../actions';
import { QUALIFICATIONS_FETCH_REQUESTED } from '../actionTypes';

function* fetchQualifications(action) {
  const res = yield call(api.fetchQualifications, action.query);
  yield put(qualificationsFetchSucceeded(res));
}

function* fetchQualificationsSaga() {
  yield takeLatest(QUALIFICATIONS_FETCH_REQUESTED, fetchQualifications);
}

export default fetchQualificationsSaga;
