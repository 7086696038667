import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Breadcrumbs from '../Breadcrumbs';
import EmployerForm from './EmployerForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const AddEmployer = () => {
  const classes = useStyles();

  const savingEmployer = useSelector((state) => state.employers.savingEmployer);

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { text: 'Employers', to: '/employers', key: 'employers' },
          { text: 'Add Employer', key: 'add-employer' },
        ]}
      />
      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add Employer
            </Typography>
            <EmployerForm
              disabled={savingEmployer}
              initialEmployer={{
                legal_name: '',
                trading_name: '',
                abn: '',
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddEmployer;
