import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import RedirectIf from '../RedirectIf';
import CenteredContainer from '../CenteredContainer';
import useAuthUser from '../../hooks/useAuthUser';

const useStyles = makeStyles((theme) => ({
  bodyText: {
    marginBottom: theme.spacing(2),
  },
}));

const Pending = () => {
  const classes = useStyles();
  const { authUserIsPending } = useAuthUser();

  return (
    <CenteredContainer>
      <RedirectIf condition={!authUserIsPending} to="/" />
      <Typography
        className={classes.bodyText}
        key="pending-body-1"
        variant="body1"
      >
        You have successfully registered a new account. Your account must be
        approved before you will be able to access the system. You will receive
        an email notification when this occurs.
      </Typography>
      <Typography key="pending-body-2" variant="body1">
        If this does not occur within 3 business days, please contact your SBAT
        officer.
      </Typography>
    </CenteredContainer>
  );
};

export default Pending;
