import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { studentFetchSucceeded, studentNotFound } from '../actions';
import { STUDENT_FETCH_REQUESTED } from '../actionTypes';

function* fetchStudent(action) {
  try {
    const student = yield call(api.fetchStudent, action.id);
    yield put(studentFetchSucceeded({ student }));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(studentNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchStudentSaga() {
  yield takeLatest(STUDENT_FETCH_REQUESTED, fetchStudent);
}

export default fetchStudentSaga;
