import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import Form from '../form/Form';
import TextInput from '../form/TextInput';
import getValidationSchema, {
  stringValidation,
  phoneNumberValidation,
  emailValidation,
} from '../form/ValidationSchema';
import { contactSaveRequested } from '../../redux/actions';

const ContactForm = ({ disabled, initialContact }) => {
  const schema = {
    email: {
      validation: emailValidation,
      required: true,
    },
    name: {
      validation: stringValidation,
      required: false,
    },
    phone: {
      validation: phoneNumberValidation,
      required: false,
    },
    fax: {
      validation: phoneNumberValidation,
      required: false,
    },
    mobile: {
      validation: phoneNumberValidation,
      required: false,
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
    },
  });

  const saveErrors = useSelector((state) => state.contacts.saveErrors);

  const onSubmit = (data) => {
    dispatch(
      contactSaveRequested({
        id: initialContact.id,
        data,
      })
    );
  };

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <TextInput
        name="email"
        label="Email"
        defaultValue={initialContact.email}
        control={control}
        hasError={Boolean(errors.email || saveErrors.email)}
        errorText={errors?.email?.message ?? saveErrors?.email?.message}
      />
      <TextInput
        name="name"
        label="Name"
        defaultValue={initialContact.name}
        control={control}
        hasError={Boolean(errors.name || saveErrors.name)}
        errorText={errors?.name?.message ?? saveErrors?.name?.message}
      />
      <TextInput
        name="phone"
        label="Phone"
        defaultValue={initialContact.phone}
        control={control}
        hasError={Boolean(errors.phone || saveErrors.phone)}
        errorText={errors?.phone?.message ?? saveErrors?.phone?.message}
      />
      <TextInput
        name="fax"
        label="Fax"
        defaultValue={initialContact.fax}
        control={control}
        hasError={Boolean(errors.fax || saveErrors.fax)}
        errorText={errors?.fax?.message ?? saveErrors?.fax?.message}
      />
      <TextInput
        name="mobile"
        label="Mobile"
        defaultValue={initialContact.mobile}
        control={control}
        hasError={Boolean(errors.mobile || saveErrors.mobile)}
        errorText={errors?.mobile?.message ?? saveErrors?.mobile?.message}
      />
    </Form>
  );
};

export default ContactForm;
