import {
  API_CONFIG_FETCH_REQUESTED,
  API_CONFIG_FETCH_SUCCEEDED,
} from './actionTypes';

export const apiConfigFetchRequested = () => ({
  type: API_CONFIG_FETCH_REQUESTED,
});

export const apiConfigFetchSucceeded = (apiConfig) => ({
  type: API_CONFIG_FETCH_SUCCEEDED,
  apiConfig,
});
