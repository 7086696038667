import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ResourceTableHeadLabel from './ResourceTableHeadLabel';
import ResourceTableCell from './ResourceTableCell';

const useStyles = makeStyles((theme) => ({
  noData: {
    padding: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.sbatColors.primary.lightBlue,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  cellOverflow: {
    width: '100%',
    textOverflow: 'ellipsis',
    minWidth: '200px',
    maxWidth: 0 /* force overflow */,
    overflowX: 'hidden',
  },
}));

const ResourceTableHead = ({
  headers,
  resourceQuery,
  updateResourceQuerySort,
}) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {headers.map((header) => (
          <ResourceTableCell key={header.id}>
            <ResourceTableHeadLabel
              sort={resourceQuery.sort}
              header={header}
              updateResourceQuerySort={updateResourceQuerySort}
            />
          </ResourceTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ResourceTableHead;
