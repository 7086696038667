import {
  SCHOOLS_FETCH_REQUESTED,
  SCHOOLS_FETCH_SUCCEEDED,
  SCHOOL_SAVE_REQUESTED,
  SCHOOL_SAVE_SUCCEEDED,
  SCHOOL_FETCH_REQUESTED,
  SCHOOL_FETCH_SUCCEEDED,
  SCHOOL_NOT_FOUND,
  UPDATE_SCHOOLS_QUERY_SORT,
  UPDATE_SCHOOLS_QUERY_SEARCH,
  UPDATE_SCHOOLS_QUERY_FILTER,
  RESET_SCHOOLS_QUERY,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  schools: null,
  fetchingSchools: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'name',
      asc: true,
    },
  },
  school: null,
  fetchingSchool: false,
  savingSchool: false,
  schoolNotFound: false,
};

const schoolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHOOLS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSchools: true,
      };
    }

    case SCHOOLS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSchools: false,
        schools:
          state.schools === null
            ? action.schools
            : [...state.schools, ...action.schools], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_SCHOOLS_QUERY_SORT: {
      return {
        ...state,
        fetchingSchools: false,
        schools: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_SCHOOLS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingSchools: false,
        schools: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case UPDATE_SCHOOLS_QUERY_FILTER: {
      return {
        ...state,
        fetchingSchools: false,
        schools: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {
            ...state.query.filters,
            [action.filterId]: action.optionValue,
          },
        },
      };
    }

    case RESET_SCHOOLS_QUERY: {
      return {
        ...state,
        fetchingSchools: false,
        schools: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case SCHOOL_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSchool: true,
        school: null,
        schoolNotFound: false,
      };
    }

    case SCHOOL_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSchool: false,
        school: action.school,
      };
    }

    case SCHOOL_NOT_FOUND: {
      return {
        ...state,
        fetchingSchool: false,
        schoolNotFound: true,
      };
    }

    case SCHOOL_SAVE_REQUESTED: {
      return {
        ...state,
        savingSchool: true,
      };
    }
    case SCHOOL_SAVE_SUCCEEDED: {
      // Need to reload all schools if saved
      return {
        ...state,
        schools: null,
        school: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingSchool: false,
      };
    }

    case AUTH_USER_PROFILE_SAVE_SUCCEEDED: {
      return {
        ...state,
        schools: null,
        query: {
          ...state.query,
          cursor: null,
        },
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default schoolsReducer;
