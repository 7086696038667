import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { externalSbatSaveSucceeded, userNotify } from '../actions';
import { EXTERNAL_SBAT_SAVE_REQUESTED } from '../actionTypes';
import { SBAT_ACTION_SAVE, SBAT_ACTION_SUBMIT } from '../../sbatActions';

function getApiAction(action) {
  switch (action) {
    case SBAT_ACTION_SAVE:
      return api.saveExternalSbat;
    case SBAT_ACTION_SUBMIT:
      return api.submitExternalSbat;
    default:
      return undefined;
  }
}

function* saveExternalSbat({ id, data, action, token }) {
  yield call(getApiAction(action), id, data, token);
  // update state and redirect
  yield put(externalSbatSaveSucceeded());
  if (action === SBAT_ACTION_SUBMIT) {
    yield put(userNotify('SBAT progressed successfully'));
  } else {
    yield put(userNotify('SBAT saved successfully'));
  }
}

function* saveExternalSbatSaga() {
  yield takeLatest(EXTERNAL_SBAT_SAVE_REQUESTED, saveExternalSbat);
}

export default saveExternalSbatSaga;
