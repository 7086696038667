import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  label: {
    marginRight: theme.spacing(3),
    fontSize: 16,
    fontWeight: 500,
    color: grey[600],
  },
  error: {
    color: theme.palette.error.main,
  },
  errorBox: {
    borderRadius: '5px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
  },
  requiredBox: {
    borderRadius: '5px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.required.border,
  },
}));

const MultiCheckboxInput = ({
  control,
  defaultValue,
  name,
  label,
  options,
  hasError,
  errorText,
  disabled = false,
  requiredOnSubmit = false,
}) => {
  const classes = useStyles();

  const [checkedValues, setCheckedValues] = useState(
    defaultValue ? defaultValue : []
  );

  function handleCheck(optionValue) {
    const newValues = checkedValues.includes(optionValue)
      ? checkedValues.filter((value) => value !== optionValue)
      : [...(checkedValues ?? []), optionValue];
    setCheckedValues(newValues);
    return newValues;
  }

  return (
    <Box>
      <Box className={classes.root + ' ' + (hasError ? classes.errorBox : requiredOnSubmit ? classes.requiredBox : '')}>
        <Box className={classes.label}>{label}</Box>
        <Box>
          {options.map((option) => (
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox />}
                  control={control}
                  checked={checkedValues.includes(option.id)}
                  onChange={() => handleCheck(option.id)}
                  name={name}
                />
              }
              key={option.id}
              label={option.name}
              disabled={disabled}
            />
          ))}
        </Box>
      </Box>
      {hasError && <Box className={classes.error}>{errorText}</Box>}
    </Box>
  );
};

export default MultiCheckboxInput;
