import React from 'react';

import DetailsList from '../DetailsList';

const EmployerDetails = ({ employer }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Legal Name',
          text: employer.legal_name,
        },
        {
          label: 'Trading Name',
          text: employer.trading_name,
        },
        {
          label: 'ABN',
          text: employer.abn,
        },
      ]}
    />
  );
};

export default EmployerDetails;
