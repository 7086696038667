import { useSelector } from 'react-redux';

const useSbatStatuses = () => {
  const sbatStatuses = useSelector(
    (state) => state.apiConfig.config.sbat.statuses
  );

  return {
    sbatStatuses,
  };
};

export default useSbatStatuses;
