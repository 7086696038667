import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { authUserLogoutRequested } from '../../redux/actions';
import useAuthUser from '../../hooks/useAuthUser';
import RenderIfHasAbility from '../RenderIfHasAbility';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  userDetails: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontSize: '16px',
    fontWeight: 700,
  },
  username: {
    fontStyle: 'italic',
  },
  userRole: {
    fontStyle: 'italic',
  },
  userDetailsButton: {
    marginRight: theme.spacing(1),
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser, authUserHasRole, authUserIsActive } = useAuthUser();

  const handleLogout = () => {
    dispatch(authUserLogoutRequested());
  };

  return (
    <>
      <div className={classes.toolbar} />
      <Box className={classes.userDetails}>
        <Box>
          <Box className={classes.name}>
            {authUser.name.first} {authUser.name.last}
          </Box>
          <Box className={classes.username}>{authUser.username}</Box>
          <Box className={classes.userRole}>{authUser.role.name}</Box>
        </Box>
        <Box>
          {authUserHasRole && authUserIsActive && (
            <IconButton
              component={Link}
              to="/profile"
              edge="start"
              title="Profile"
              className={classes.userDetailsButton}
            >
              <AccountCircle />
            </IconButton>
          )}
          <IconButton onClick={handleLogout} edge="start" title="Log out">
            <ExitToApp />
          </IconButton>
        </Box>
      </Box>

      <Divider />
      {authUserHasRole && authUserIsActive && (
        <List>
          <ListItem button component={Link} to="/">
            <ListItemText primary="Dashboard" />
          </ListItem>
          <RenderIfHasAbility resource="users" ability="viewAny">
            <ListItem button component={Link} to="/users">
              <ListItemText primary="Users" />
            </ListItem>
          </RenderIfHasAbility>
          <ListItem button component={Link} to="/sbats">
            <ListItemText primary="SBATs" />
          </ListItem>
          <ListItem button component={Link} to="/employers">
            <ListItemText primary="Employers" />
          </ListItem>
          <RenderIfHasAbility resource="contacts" ability="viewAny">
            <ListItem button component={Link} to="/contacts">
              <ListItemText primary="Contacts" />
            </ListItem>
          </RenderIfHasAbility>
          <RenderIfHasAbility resource="schools" ability="viewAny">
            <ListItem button component={Link} to="/schools">
              <ListItemText primary="Schools" />
            </ListItem>
          </RenderIfHasAbility>
          <RenderIfHasAbility resource="students" ability="viewAny">
            <ListItem button component={Link} to="/students">
              <ListItemText primary="Students" />
            </ListItem>
          </RenderIfHasAbility>
          <RenderIfHasAbility resource="qualifications" ability="viewAny">
            <ListItem button component={Link} to="/qualifications">
              <ListItemText primary="Qualifications" />
            </ListItem>
          </RenderIfHasAbility>
        </List>
      )}
    </>
  );
};

export default SideBar;
