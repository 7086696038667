import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
  employersFetchRequested,
  updateEmployersQuerySort,
  updateEmployersQuerySearch,
  resetEmployersQuery,
} from '../../redux/employers/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Employers = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'Employers', key: 'employers' }]} />
        <Button
          component={Link}
          to="/employers/add"
          variant="contained"
          color="secondary"
        >
          Add Employer
        </Button>
      </Box>

      <ResourceTable
        headers={[
          { id: 'legal_name', label: 'Legal Name', sortable: true },
          { id: 'trading_name', label: 'Trading Name', sortable: true },
          { id: 'abn', label: 'ABN', sortable: true },
          { id: 'duty_of_care', label: 'Duty of care', sortable: true },
          { id: 'actions', label: '', sortable: false },
        ]}
        mapResourceToColumns={(employer) => {
          return [
            { label: employer.legal_name },
            { label: employer.trading_name },
            { label: employer.abn },
            { label: employer.duty_of_care ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/employers/${employer.id}`,
                      color: 'secondary',
                      text: 'Manage Employer',
                      key: 'manage-employer',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={employersFetchRequested}
        isFetchingResourceSelector={(state) =>
          state.employers.fetchingEmployers
        }
        resourcesSelector={(state) => state.employers.employers}
        resourceQuerySelector={(state) => state.employers.query}
        updateResourceQuerySort={updateEmployersQuerySort}
        updateResourceQuerySearch={updateEmployersQuerySearch}
        resetResourceQuery={resetEmployersQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/employers/export`}
        searchLabel="Search for employers"
      />
    </>
  );
};

export default Employers;
