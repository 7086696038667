import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import {
  qualificationSaveSucceeded,
  qualificationSaveFailed,
  userNotify,
} from '../actions';
import { QUALIFICATION_SAVE_REQUESTED } from '../actionTypes';

function* saveQualification({ id, data }) {
  try {
    const qualification = id
      ? yield call(api.saveQualification, id, data)
      : yield call(api.createQualification, data);
    // update state and redirect
    yield put(qualificationSaveSucceeded({ qualification }));
    yield put(userNotify('Qualification saved successfully'));
    yield put(push('/qualifications'));
  } catch (e) {
    yield put(userNotify(e?.response?.data?.message ?? 'Error', false));
    if (e?.response?.status === 422 && e.response?.data?.errors) {
      // 422 failed backend validation
      // massage error response to object
      // {[field_name]: {message: 'error message'}}
      const errors = Object.entries(e.response.data.errors).reduce(
        (acc, [key, value]) => {
          acc[key] = { message: value.join('; ') };
          return acc;
        },
        {}
      );
      yield put(qualificationSaveFailed({ errors }));
    } else {
      yield put(qualificationSaveFailed());
    }
  }
}

function* saveQualificationSaga() {
  yield takeLatest(QUALIFICATION_SAVE_REQUESTED, saveQualification);
}

export default saveQualificationSaga;
