import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import {
  contactsFetchRequested,
  updateContactsQuerySort,
  updateContactsQuerySearch,
  resetContactsQuery,
} from '../../redux/contacts/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Contacts = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'Contacts', key: 'contacts' }]} />
      </Box>

      <ResourceTable
        headers={[
          { id: 'email', label: 'email', sortable: true },
          { id: 'name', label: 'Name', sortable: true },
          { id: 'actions', label: '', sortable: false },
        ]}
        mapResourceToColumns={(contact) => {
          return [
            { label: contact.email },
            { label: contact.name },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/contacts/${contact.id}`,
                      color: 'secondary',
                      text: 'Manage Contact',
                      key: 'manage-contact',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={contactsFetchRequested}
        isFetchingResourceSelector={(state) => state.contacts.fetchingContacts}
        resourcesSelector={(state) => state.contacts.contacts}
        resourceQuerySelector={(state) => state.contacts.query}
        updateResourceQuerySort={updateContactsQuerySort}
        updateResourceQuerySearch={updateContactsQuerySearch}
        resetResourceQuery={resetContactsQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/contacts/export`}
        searchLabel="Search for contacts"
      />
    </>
  );
};

export default Contacts;
