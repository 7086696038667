import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TabPanel = ({ value, index, children }) => {
  const classes = useStyles();

  return (
    value === index && (
      <Paper elevation={2} className={classes.root}>
        {children}
      </Paper>
    )
  );
};

export default TabPanel;
