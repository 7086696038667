import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Breadcrumbs from '../Breadcrumbs';
import StudentForm from './StudentForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const AddStudent = () => {
  const classes = useStyles();

  const savingStudent = useSelector((state) => state.students.savingStudent);

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { text: 'Students', to: '/students', key: 'students' },
          { text: 'Add Student', key: 'add-student' },
        ]}
      />
      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add Student
            </Typography>
            <StudentForm
              disabled={savingStudent}
              initialStudent={{}}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddStudent;
