import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import TabPanel from './TabPanel';

const TabSections = ({ sections }) => {
  const defaultSelected = sections.findIndex((o) => o.selected);
  const defaultValue = defaultSelected !== -1 ? defaultSelected : 0;
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {sections.map((section, index) => (
          <Tab key={index} label={section.label} icon={section.icon} />
        ))}
      </Tabs>
      {sections.map((section, index) => (
        <Grid key={index} container spacing={1}>
          <Grid item xs={12} lg={section.fullWidth ? 12 : 6}>
            <TabPanel value={value} index={index}>
              {section.contents}
            </TabPanel>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default TabSections;
