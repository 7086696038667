import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { authUserFetchFailed, authUserFetchSucceeded } from '../auth/actions';
import { AUTH_USER_FETCH_REQUESTED } from '../auth/actionTypes';

function* fetchAuthUser() {
  try {
    const user = yield call(api.fetchAuthUser);
    yield put(authUserFetchSucceeded(user));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(authUserFetchFailed());
      return;
    }

    throw e;
  }
}

function* fetchAuthUserSaga() {
  yield takeLatest(AUTH_USER_FETCH_REQUESTED, fetchAuthUser);
}

export default fetchAuthUserSaga;
