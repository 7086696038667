import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './theme';

const AppWrappers = ({ store, history, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <CssBaseline />
        <Provider store={store}>
          <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default AppWrappers;
