import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';

import { schoolSaveRequested, schoolFetchRequested } from '../../redux/actions';
import Breadcrumbs from '../Breadcrumbs';
import SchoolForm from './SchoolForm';
import Error404 from '../errors/Error404';
import TabSections from '../TabSections';
import SchoolDetails from './SchoolDetails';
import ResourceHistory from '../history/ResourceHistory';

const School = () => {
  const dispatch = useDispatch();

  const school = useSelector((state) => state.schools.school);
  const schoolNotFound = useSelector((state) => state.schools.schoolNotFound);
  const savingSchool = useSelector((state) => state.schools.savingSchool);

  const { id } = useParams();

  // always fetch employer when page loads - this prevents stale data
  useEffect(() => {
    dispatch(schoolFetchRequested(id));
  }, []);

  const onSubmit = (data) => {
    dispatch(schoolSaveRequested({ id, data }));
  };

  return schoolNotFound ? (
    <Error404 />
  ) : (
    school && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'Schools', to: '/schools', key: 'schools' },
            { text: school.name, key: 'edit-school' },
          ]}
        />
        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <SchoolDetails school={school} />,
            },
            {
              label: 'Edit',
              icon: <EditIcon />,
              contents: (
                <SchoolForm
                  onSubmit={onSubmit}
                  disabled={savingSchool}
                  initialSchool={school}
                />
              ),
            },
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={school.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default School;
