import {
  AUTH_USER_FETCH_REQUESTED,
  AUTH_USER_LOGOUT_SUCCEEDED,
  AUTH_USER_FETCH_FAILED,
  AUTH_USER_FETCH_SUCCEEDED,
  SYSTEM_USER_LOGIN_REQUESTED,
  SYSTEM_USER_LOGIN_FAILED,
  SYSTEM_USER_LOGIN_SUCCEEDED,
  AUTH_USER_REGISTER_REQUESTED,
  AUTH_USER_REGISTER_SUCCEEDED,
  AUTH_USER_PROFILE_SAVE_REQUESTED,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  user: null,
  checkedAuth: false,
  checkingAuth: false,
  systemLoginRequested: false,
  registeringUser: false,
  authUserProfileSaving: false,
  impersonatedByUser: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_FETCH_REQUESTED: {
      return {
        ...state,
        checkingAuth: true,
      };
    }

    case AUTH_USER_FETCH_SUCCEEDED: {
      return {
        ...state,
        user: action.user,
        checkedAuth: true,
      };
    }

    case AUTH_USER_FETCH_FAILED: {
      return {
        ...state,
        checkedAuth: true,
      };
    }

    case AUTH_USER_LOGOUT_SUCCEEDED: {
      return {
        ...state,
        user: null,
        checkedAuth: true,
      };
    }

    case SYSTEM_USER_LOGIN_REQUESTED: {
      return {
        ...state,
        systemLoginRequested: true,
      };
    }

    case SYSTEM_USER_LOGIN_FAILED: {
      return {
        ...state,
        systemLoginRequested: false,
      };
    }

    case SYSTEM_USER_LOGIN_SUCCEEDED: {
      return {
        ...state,
        user: action.user,
        systemLoginRequested: false,
      };
    }

    case AUTH_USER_REGISTER_REQUESTED: {
      return {
        ...state,
        registeringUser: true,
      };
    }

    case AUTH_USER_REGISTER_SUCCEEDED: {
      return {
        ...state,
        registeringUser: false,
        user: action.user,
      };
    }

    case AUTH_USER_PROFILE_SAVE_REQUESTED: {
      return { ...state, authUserProfileSaving: true };
    }

    case AUTH_USER_PROFILE_SAVE_SUCCEEDED: {
      return { ...state, user: action.user, authUserProfileSaving: false };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...state,
        impersonatedByUser: state.user,
        user: action.user,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
