import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  studentsFetchRequested,
  updateStudentsQuerySort,
  updateStudentsQuerySearch,
  updateStudentsQueryFilter,
  resetStudentsQuery,
} from '../../redux/students/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';
import {
  FILTER_TYPE_SELECT,
  FILTER_TYPE_SELECT_AUTOCOMPLETE,
  FILTER_TYPE_CHECKBOX,
} from '../resourceTable/filterTypes';
import useAuthUser from '../../hooks/useAuthUser';
import useRtos from '../../hooks/useRtos';
import useSchools from '../../hooks/useSchools';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Students = () => {
  const classes = useStyles();
  const { rtos } = useRtos();
  const { schoolsUserCanView: schools } = useSchools();
  const { authUserIsSchool, authUserIsOfficer } = useAuthUser();

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'Students', key: 'students' }]} />
        <Button
          component={Link}
          to="/students/add"
          variant="contained"
          color="secondary"
        >
          Add Student
        </Button>
      </Box>

      <ResourceTable
        headers={[
          { id: 'first_name', label: 'First Name', sortable: true },
          { id: 'last_name', label: 'Last Name', sortable: true },
          { id: 'schools.name', label: 'School', sortable: true },
          { id: 'rtos.name', label: 'TQI team', sortable: true },
          { id: 'actions', label: '', sortable: false },
        ]}
        filters={[
          !authUserIsSchool && {
            id: 'school_id',
            label: 'School',
            type: FILTER_TYPE_SELECT_AUTOCOMPLETE,
            options: schools.map((school) => {
              return {
                label: school.name,
                value: school.id,
              };
            }),
          },
          !authUserIsSchool &&
            {
              id: 'rto_id',
              label: 'TQI team',
              type: FILTER_TYPE_SELECT,
              options: rtos.map((rto) => {
                return {
                  label: rto.name,
                  value: rto.id,
                };
              }),
            },
          authUserIsOfficer && {
            id: 'my_assigned_schools',
            label: 'My Assigned Schools',
            type: FILTER_TYPE_CHECKBOX,
          },
        ].filter(Boolean)}
        mapResourceToColumns={(student) => {
          return [
            { label: student.name.first },
            { label: student.name.last },
            { label: student.school.name },
            { label: student.school.rto.name },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/students/${student.id}`,
                      color: 'secondary',
                      text: 'Manage Student',
                      key: 'manage-student',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={studentsFetchRequested}
        isFetchingResourceSelector={(state) => state.students.fetchingStudents}
        resourcesSelector={(state) => state.students.students}
        resourceQuerySelector={(state) => state.students.query}
        updateResourceQuerySort={updateStudentsQuerySort}
        updateResourceQuerySearch={updateStudentsQuerySearch}
        updateResourceQueryFilter={updateStudentsQueryFilter}
        resetResourceQuery={resetStudentsQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/students/export`}
        searchLabel="Search for students"
      />
    </>
  );
};

export default Students;
