import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  redirectToSbatsWithQuery,
  requestPendingSbatsFetch,
} from '../../redux/actions';
import useAuthUser from '../../hooks/useAuthUser';

const useStyles = makeStyles((theme) => ({
  pendingTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  noPendingBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    color: theme.sbatColors.secondary.darkBlue,
  },
}));

const PendingSbats = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUserIsOfficer } = useAuthUser();

  const pendingSbats = useSelector((state) => state.sbats.pendingSbats);
  const fetchingPendingSbats = useSelector(
    (state) => state.users.fetchingPendingSbats
  );

  if (!pendingSbats && !fetchingPendingSbats) {
    dispatch(requestPendingSbatsFetch());
  }

  const redirectToViewAll = () => {
    const filters = { require_my_progression: true };

    if (authUserIsOfficer) {
      filters.my_assigned_schools = true;
    }

    dispatch(
      redirectToSbatsWithQuery({
        filters,
      })
    );
  };

  return (
    pendingSbats && (
      <Box>
        <Box className={classes.pendingTitleBox}>
          <Typography component="h1" variant="h5">
            These SBATs require your progression
          </Typography>
          <Button
            onClick={() => redirectToViewAll()}
            variant="outlined"
            color="primary"
          >
            View All
          </Button>
        </Box>
        <Paper>
          {pendingSbats.length === 0 ? (
            <Box className={classes.noPendingBox}>
              <Typography component="h1" variant="h5">
                There are currently no SBATs that require your attention
              </Typography>
            </Box>
          ) : (
            <List>
              {pendingSbats.map((sbat) => (
                <ListItem key={sbat.id}>
                  <ListItemText
                    primary={`${sbat.student.name.first} ${sbat.student.name.last}`}
                    secondary={
                      <>
                        {sbat.status.name} | {sbat.student.school.name}
                      </>
                    }
                  />
                  <Button
                    component={Link}
                    to={`/sbats/${sbat.id}`}
                    variant="outlined"
                    color="secondary"
                  >
                    Manage SBAT
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Box>
    )
  );
};

export default PendingSbats;
