import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Form from '../form/Form';
import SelectInput from '../form/SelectInput';
import { userSaveRequested } from '../../redux/actions';
import AutoCompleteInput from '../form/AutoCompleteInput';
import { ROLE_ID_SCHOOL, ROLE_ID_SBAT_OFFICER } from '../../roles';
import useRoles from '../../hooks/useRoles';
import useSchools from '../../hooks/useSchools';
import useRtos from '../../hooks/useRtos';
import useUserStatuses from '../../hooks/useUserStatuses';

const UserForm = ({ initialUser }) => {
  const dispatch = useDispatch();
  const { userStatuses } = useUserStatuses();
  const { rtos } = useRtos();
  const { rolesUserCanView } = useRoles();
  const { schoolsUserCanView } = useSchools();
  const savingUser = useSelector((state) => state.users.savingUser);

  const { control, handleSubmit, watch, errors } = useForm();

  const onSubmit = (data) => {
    dispatch(
      userSaveRequested(initialUser.id, {
        role_id: data.role_id,
        status_id: data.status_id,
        school_id: data.school ? data.school.id : null,
        rto_id: data.rto ? data.rto.id : null,
      })
    );
  };

  const selectedRoleId = watch('role_id', initialUser.role.id);
  const userSchool = schoolsUserCanView.find((school) => {
    return school.id === (initialUser.school ? initialUser.school.id : null);
  });

  const userRto = rtos.find((rto) => {
    return rto.id === (initialUser.rto ? initialUser.rto.id : null);
  });

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={savingUser}
    >
      <SelectInput
        options={rolesUserCanView}
        control={control}
        disabled={rolesUserCanView.length === 1}
        defaultValue={initialUser.role.id}
        name="role_id"
        label="Role"
        key="role_id"
        rules={{ required: true }}
      />
      {selectedRoleId === ROLE_ID_SCHOOL && (
        <AutoCompleteInput
          options={schoolsUserCanView}
          defaultValue={userSchool}
          label="School"
          key="school"
          name="school"
          control={control}
          hasError={Boolean(errors.school)}
          errorText={errors.school && 'Required'}
          rules={{ required: true }}
        />
      )}
      {selectedRoleId === ROLE_ID_SBAT_OFFICER && (
        <AutoCompleteInput
          options={rtos}
          label="TQI team"
          defaultValue={userRto}
          key="rto"
          name="rto"
          control={control}
          hasError={Boolean(errors.rto)}
          errorText={errors.rto && 'Required'}
          rules={{ required: true }}
        />
      )}
      <SelectInput
        options={userStatuses}
        control={control}
        defaultValue={initialUser.status.id}
        name="status_id"
        label="Status"
        key="status_id"
        rules={{ required: true }}
      />
    </Form>
  );
};

export default UserForm;
