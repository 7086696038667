import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  redirectToUsersWithQuery,
  requestPendingUsersFetch,
} from '../../redux/actions';
import { STATUS_ID_PENDING } from '../../statuses';
import {
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_SCHOOL,
  ROLE_ID_ADMIN,
} from '../../roles';
import useAuthUser from '../../hooks/useAuthUser';

const useStyles = makeStyles((theme) => ({
  pendingTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  noPendingUsersBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    color: theme.sbatColors.secondary.darkBlue,
  },
}));

const PendingUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    authUserIsOfficer,
    authUserIsAdmin,
    authUserIsSystem,
  } = useAuthUser();

  const pendingUsers = useSelector((state) => state.users.pendingUsers);
  const fetchingPendingUsers = useSelector(
    (state) => state.users.fetchingPendingUsers
  );

  if (!pendingUsers && !fetchingPendingUsers) {
    dispatch(requestPendingUsersFetch());
  }

  const redirectToViewAll = () => {
    const filters = { status_id: STATUS_ID_PENDING };

    if (authUserIsOfficer) {
      filters.my_assigned_schools = true;
    }

    if (authUserIsAdmin) {
      filters.role_id = ROLE_ID_SBAT_OFFICER;
    }

    if (authUserIsSystem) {
      filters.role_id = ROLE_ID_ADMIN;
    }

    dispatch(
      redirectToUsersWithQuery({
        filters,
      })
    );
  };

  return (
    pendingUsers && (
      <Box>
        <Box className={classes.pendingTitleBox}>
          <Typography component="h1" variant="h5">
            These users require your approval
          </Typography>
          <Button
            onClick={() => redirectToViewAll()}
            variant="outlined"
            color="primary"
          >
            View All
          </Button>
        </Box>
        <Paper>
          {pendingUsers.length === 0 ? (
            <Box className={classes.noPendingUsersBox}>
              <Typography component="h1" variant="h5">
                There are currently no pending users
              </Typography>
            </Box>
          ) : (
            <List>
              {pendingUsers.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText
                    primary={user.username}
                    secondary={
                      <>
                        {user.role.name}
                        {user.role.id === ROLE_ID_SCHOOL &&
                          ` | ${user.school.name}`}
                        {user.role.id === ROLE_ID_SBAT_OFFICER &&
                          ` | ${user.rto.name}`}
                      </>
                    }
                  />
                  <Button
                    component={Link}
                    to={`/users/${user.id}`}
                    variant="outlined"
                    color="secondary"
                  >
                    Manage User
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Box>
    )
  );
};

export default PendingUsers;
