import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.sbatColors.secondary.darkBlue,
  },
  error: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Notifications = () => {
  const classes = useStyles();

  const currentNotification = useSelector((state) => state.ui.notifications[0]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={Boolean(currentNotification)}
    >
      {currentNotification && (
        <SnackbarContent
          message={currentNotification.msg}
          className={
            currentNotification.success ? classes.success : classes.error
          }
        />
      )}
    </Snackbar>
  );
};

export default Notifications;
