import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import ResourceTableHead from './ResourceTableHead';
import ResourceTableBody from './ResourceTableBody';
import ResourceTableToolbar from './ResourceTableToolbar';

const useStyles = makeStyles((theme) => ({
  noRecordsBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    color: theme.sbatColors.secondary.darkBlue,
  },
}));

const ResourceTable = ({
  headers,
  filters,
  mapResourceToColumns,
  requestResourceFetch,
  resourcesSelector,
  isFetchingResourceSelector,
  resourceQuerySelector,
  updateResourceQuerySort,
  updateResourceQuerySearch,
  exportResourcesUrl,
  searchLabel,
  updateResourceQueryFilter,
  resetResourceQuery,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resourceQuery = useSelector(resourceQuerySelector);
  const resources = useSelector(resourcesSelector);
  const isFetching = useSelector(isFetchingResourceSelector);

  if (!resources && !isFetching) {
    dispatch(requestResourceFetch(resourceQuery));
  }

  return (
    <>
      <ResourceTableToolbar
        filters={filters}
        resourceQuery={resourceQuery}
        searchLabel={searchLabel}
        updateResourceQuerySearch={updateResourceQuerySearch}
        exportResourcesUrl={exportResourcesUrl}
        updateResourceQueryFilter={updateResourceQueryFilter}
        resetResourceQuery={resetResourceQuery}
      />
      <Paper>
        {!isFetching && resources !== null && resources.length === 0 ? (
          <Box className={classes.noRecordsBox}>
            <Typography component="h1" variant="h5">
              No records match query
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table size="small">
              <ResourceTableHead
                headers={headers}
                resourceQuery={resourceQuery}
                updateResourceQuerySort={updateResourceQuerySort}
              />
              <ResourceTableBody
                columnCount={headers.length}
                resources={resources}
                requestResourceFetch={requestResourceFetch}
                isFetching={isFetching}
                mapResourceToColumns={mapResourceToColumns}
                resourceQuery={resourceQuery}
              />
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default ResourceTable;
