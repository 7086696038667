import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const ResourceTableSelectMultiFilter = ({
  filter,
  selectedOptions,
  updateResourceQueryFilter,
}) => {
  const dispatch = useDispatch();
  const inputLabel = useRef(null);

  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const selectOption = (event) => {
    dispatch(updateResourceQueryFilter(filter.id, event.target.value));
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel ref={inputLabel}>{filter.label}</InputLabel>
      <Select
        value={selectedOptions ?? []}
        onChange={(e) => {
          selectOption(e);
        }}
        labelWidth={labelWidth}
        multiple
      >
        {filter.noneOption && (
          <MenuItem value={null}>
            <em>{filter.noneOption}</em>
          </MenuItem>
        )}
        {filter.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ResourceTableSelectMultiFilter;