import React from 'react';
import abilities from '../abilities';
import useAuthUser from '../hooks/useAuthUser';

const RenderIfHasAbility = ({ ability, resource, children }) => {
  const { authUser } = useAuthUser();
  const hasAbility = abilities[resource][ability](authUser);

  return hasAbility ? <>{children}</> : <></>;
};

export default RenderIfHasAbility;
