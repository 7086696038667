import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Form from '../form/Form';
import SelectInput from '../form/SelectInput';

const SchoolForm = ({ onSubmit, disabled, initialSchool }) => {
  const { control, handleSubmit, errors } = useForm();
  const rtos = useSelector((state) => state.apiConfig.config.rtos);

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <SelectInput
        name="rto_id"
        label="TQI team"
        key="rto_id"
        options={rtos}
        control={control}
        defaultValue={initialSchool.rto.id}
        hasError={Boolean(errors.rto_id)}
        errorText={errors.rto_id && 'Required'}
        rules={{ required: true }}
      />
    </Form>
  );
};

export default SchoolForm;
