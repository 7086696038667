import {
  USERS_FETCH_REQUESTED,
  USERS_FETCH_SUCCEEDED,
  UPDATE_USERS_QUERY_SORT,
  USER_FETCH_REQUESTED,
  USER_FETCH_SUCCEEDED,
  USER_NOT_FOUND,
  USER_SAVE_REQUESTED,
  UPDATE_USERS_QUERY_SEARCH,
  UPDATE_USERS_QUERY_FILTER,
  RESET_USERS_QUERY,
  USER_SAVE_SUCCEEDED,
  PENDING_USERS_FETCH_REQUESTED,
  PENDING_USERS_FETCH_SUCCEEDED,
  REDIRECT_TO_USERS_WITH_QUERY,
  SET_USERS_QUERY,
} from './actionTypes';

export const usersFetchRequested = (query) => ({
  type: USERS_FETCH_REQUESTED,
  query,
});

export const usersFetchSucceeded = ({ page, next = null }) => ({
  type: USERS_FETCH_SUCCEEDED,
  users: page,
  next,
});

export const updateUsersQuerySort = (id, asc) => ({
  type: UPDATE_USERS_QUERY_SORT,
  id,
  asc,
});

export const updateUsersQuerySearch = (search) => ({
  type: UPDATE_USERS_QUERY_SEARCH,
  search,
});

export const updateUsersQueryFilter = (filterId, optionValue) => ({
  type: UPDATE_USERS_QUERY_FILTER,
  filterId,
  optionValue,
});

export const resetUsersQuery = () => ({
  type: RESET_USERS_QUERY,
});

export const userFetchRequested = (id) => ({
  type: USER_FETCH_REQUESTED,
  id,
});

export const userFetchSucceeded = (user) => ({
  type: USER_FETCH_SUCCEEDED,
  user,
});

export const userNotFound = () => ({
  type: USER_NOT_FOUND,
});

export const userSaveRequested = (id, data) => ({
  type: USER_SAVE_REQUESTED,
  id,
  data,
});

export const userSaveSucceeded = (user) => ({
  type: USER_SAVE_SUCCEEDED,
  user,
});

export const requestPendingUsersFetch = () => ({
  type: PENDING_USERS_FETCH_REQUESTED,
});

export const pendingUsersFetchSucceeded = (users) => ({
  type: PENDING_USERS_FETCH_SUCCEEDED,
  users,
});

export const redirectToUsersWithQuery = (query) => ({
  type: REDIRECT_TO_USERS_WITH_QUERY,
  query,
});

export const setUsersQuery = (query) => ({
  type: SET_USERS_QUERY,
  query,
});
