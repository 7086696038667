import React from 'react';

import DetailsList from '../DetailsList';
import useAuthUser from '../../hooks/useAuthUser';

const SchoolDetails = ({ school }) => {
  const { authUserIsAdmin, authUserIsSystem } = useAuthUser();
  return (
    <DetailsList
      details={[
        {
          label: 'Name',
          text: school.name,
        },
        {
          label: 'Operational directorate',
          text: school.operational_directorate,
        },
        {
          label: 'TQI team',
          text: school.rto.name ? school.rto.name : 'None',
        },
        {
          label: 'Assigned SBAT officers',
          text:
            school.assignedOfficers.length > 0
              ? school.assignedOfficers
                  .map((officer) => {
                    return `${officer.name.first} ${officer.name.last}`;
                  })
                  .join(', ')
              : 'None',
        },
        {
          label: 'Pathway network',
          text: school.pathway_network,
        },
        {
          label: 'School performance directorate',
          text: school.school_performance_directorate,
        },
        {
          label: 'School principal network',
          text: school.school_principal_network,
        },
        {
          label: 'ASGS remoteness',
          text: school.asgs_remoteness,
        },
        {
          label: 'EPP region',
          text: school.epp_region,
        },
        (authUserIsAdmin || authUserIsSystem) && {
          label: 'EPP school group',
          text: school.epp_school_group,
        },
      ].filter(Boolean)}
    />
  );
};

export default SchoolDetails;
