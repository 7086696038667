import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import CheckboxInput from '../form/CheckboxInput';

import { authUserProfileSaveRequested } from '../../redux/actions';
import useAuthUser from '../../hooks/useAuthUser';
import useSchools from '../../hooks/useSchools';
import MultiAutoCompleteInput from '../form/MultiAutoCompleteInput';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  element: {
    marginBottom: theme.spacing(2),
  },
}));

const ProfileSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, control, errors } = useForm();
  const { authUser, authUserIsOfficer } = useAuthUser();
  const { schoolsUserCanView } = useSchools();

  const authUserProfileSaving = useSelector(
    (state) => state.auth.authUserProfileSaving
  );

  const onSubmit = (data) => {
    dispatch(
      authUserProfileSaveRequested({
        email_notifications: data.email_notifications,
        assigned_schools: data.assigned_schools
          ? data.assigned_schools.map((school) => {
              return school.id;
            })
          : null,
      })
    );
  };

  // Need to map the assigned school to the option object even though
  // attributes are identical, otherwise the equality test for what is
  // selected in the autocomplete throws errors
  const assignedSchools = authUser.assignedSchools.map((assignedSchool) => {
    return schoolsUserCanView.find((school) => {
      return assignedSchool.id === school.id;
    });
  });

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.element}>
        <CheckboxInput
          register={register}
          defaultValue={authUser.emailNotifications}
          name="email_notifications"
          label="Receive email notifications"
        />
      </Box>

      {authUserIsOfficer && (
        <Box className={classes.element}>
          <MultiAutoCompleteInput
            options={schoolsUserCanView}
            label="Assigned Schools"
            key="assigned_schools"
            name="assigned_schools"
            control={control}
            defaultValue={assignedSchools}
          />
        </Box>
      )}

      <Box className={classes.updateButtonBox}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={authUserProfileSaving}
          className={classes.submit}
        >
          Update
        </Button>
      </Box>
    </form>
  );
};

export default ProfileSettings;
