import {
  CONTACT_FETCH_REQUESTED,
  CONTACT_FETCH_SUCCEEDED,
  CONTACT_NOT_FOUND,
  CONTACTS_FETCH_REQUESTED,
  CONTACTS_FETCH_SUCCEEDED,
  CONTACT_SAVE_REQUESTED,
  CONTACT_SAVE_SUCCEEDED,
  CONTACT_SAVE_FAILED,
  UPDATE_CONTACTS_QUERY_SORT,
  UPDATE_CONTACTS_QUERY_SEARCH,
  RESET_CONTACTS_QUERY,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  contacts: null,
  fetchingContacts: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'email',
      asc: true,
    },
  },
  contact: null,
  fetchingContact: false,
  contactNotFound: false,
  savingContact: false,
  saveErrors: [],
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingContact: true,
        contact: null,
        contactNotFound: false,
      };
    }

    case CONTACT_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingContact: false,
        contact: action.contact,
      };
    }

    case CONTACT_NOT_FOUND: {
      return {
        ...state,
        fetchingContact: false,
        contactNotFound: true,
      };
    }

    case CONTACT_SAVE_REQUESTED: {
      return {
        ...state,
        savingContact: true,
      };
    }
    case CONTACT_SAVE_SUCCEEDED: {
      // Need to reload all contacts if saved
      return {
        ...state,
        contacts: null,
        contact: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingContact: false,
        saveErrors: [],
      };
    }

    case CONTACT_SAVE_FAILED: {
      return {
        ...state,
        saveErrors: action.errors ?? [],
        savingContact: false,
      };
    }

    case CONTACTS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingContacts: true,
      };
    }

    case CONTACTS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingContacts: false,
        contacts:
          state.contacts === null
            ? action.contacts
            : [...state.contacts, ...action.contacts], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_CONTACTS_QUERY_SORT: {
      return {
        ...state,
        fetchingContacts: false,
        contacts: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_CONTACTS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingContacts: false,
        contacts: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case RESET_CONTACTS_QUERY: {
      return {
        ...state,
        fetchingContacts: false,
        contacts: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default contactsReducer;
