import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';

import Breadcrumbs from '../Breadcrumbs';
import { userFetchRequested } from '../../redux/actions';
import Error404 from '../errors/Error404';
import TabSections from '../TabSections';
import UserDetails from './UserDetails';
import UserForm from './UserForm';
import ResourceHistory from '../history/ResourceHistory';

const User = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const fetchingUser = useSelector((state) => state.users.fetchingUser);
  const userNotFound = useSelector((state) => state.users.userNotFound);

  const { id } = useParams();

  useEffect(() => {
    if (
      !fetchingUser &&
      !userNotFound &&
      (!user || user.id.toString() !== id)
    ) {
      dispatch(userFetchRequested(id));
    }
  }, [dispatch, id, user, fetchingUser, userNotFound]);

  return userNotFound ? (
    <Error404 />
  ) : (
    user && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'Users', to: '/users', key: 'users' },
            { text: user.username, key: 'edit-user' },
          ]}
        />

        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <UserDetails user={user} />,
            },
            {
              label: 'Edit',
              icon: <EditIcon />,
              contents: <UserForm initialUser={user} />,
            },
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={user.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default User;
