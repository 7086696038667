import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';

import { employerFetchRequested } from '../../redux/actions';
import Breadcrumbs from '../Breadcrumbs';
import EmployerForm from './EmployerForm';
import Error404 from '../errors/Error404';
import TabSections from '../TabSections';
import EmployerDetails from './EmployerDetails';
import ResourceHistory from '../history/ResourceHistory';

const Employer = () => {
  const dispatch = useDispatch();

  const employer = useSelector((state) => state.employers.employer);
  const employerNotFound = useSelector(
    (state) => state.employers.employerNotFound
  );
  const savingEmployer = useSelector((state) => state.employers.savingEmployer);

  const { id } = useParams();

  // always fetch employer when page loads - this prevents stale data
  useEffect(() => {
    dispatch(employerFetchRequested(id));
  }, []);

  return employerNotFound ? (
    <Error404 />
  ) : (
    employer && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'Employers', to: '/employers', key: 'employers' },
            { text: employer.legal_name, key: 'edit-user' },
          ]}
        />
        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <EmployerDetails employer={employer} />,
            },
            {
              label: 'Edit',
              icon: <EditIcon />,
              contents: (
                <EmployerForm
                  disabled={savingEmployer}
                  initialEmployer={employer}
                />
              ),
            },
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={employer.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default Employer;
