import React from 'react';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../Breadcrumbs';
import PendingUsers from './PendingUsers';
import PendingSbats from './PendingSbats';
import RenderIfHasAbility from '../RenderIfHasAbility';

const Dashboard = () => {
  return (
    <>
      <Breadcrumbs crumbs={[]} />
      <Grid container spacing={10}>
        <RenderIfHasAbility resource="sbats" ability="viewPending">
          <Grid item xs={12} lg={6}>
            <PendingSbats />
          </Grid>
        </RenderIfHasAbility>
        <RenderIfHasAbility resource="users" ability="viewAny">
          <Grid item xs={12} lg={6}>
            <PendingUsers />
          </Grid>
        </RenderIfHasAbility>
      </Grid>
    </>
  );
};

export default Dashboard;
