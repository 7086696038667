import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import {
  contactSaveSucceeded,
  contactSaveFailed,
  userNotify,
} from '../actions';
import { CONTACT_SAVE_REQUESTED } from '../actionTypes';

function* saveContact({ id, data }) {
  try {
    const contact = id
      ? yield call(api.saveContact, id, data)
      : yield call(api.createContact, data);
    // update state and redirect
    yield put(contactSaveSucceeded({ contact }));
    yield put(userNotify('Contact saved successfully'));
    yield put(push('/contacts'));
  } catch (e) {
    yield put(userNotify(e?.response?.data?.message ?? 'Error', false));
    if (e?.response?.status === 422 && e.response?.data?.errors) {
      // 422 failed backend validation
      // massage error response to object
      // {[field_name]: {message: 'error message'}}
      const errors = Object.entries(e.response.data.errors).reduce(
        (acc, [key, value]) => {
          acc[key] = { message: value.join('; ') };
          return acc;
        },
        {}
      );
      yield put(contactSaveFailed({ errors }));
    } else {
      yield put(contactSaveFailed());
    }
  }
}

function* saveContactSaga() {
  yield takeLatest(CONTACT_SAVE_REQUESTED, saveContact);
}

export default saveContactSaga;
