import React from 'react';
import Bread from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Breadcrumbs: {
    paddingBottom: theme.spacing(2),
  },
}));

const Breadcrumbs = ({ crumbs }) => {
  const classes = useStyles();
  return (
    <Bread
      separator="›"
      aria-label="breadcrumbs"
      className={classes.Breadcrumbs}
    >
      <Link color="inherit" component={RouterLink} to="/">
        SBAT
      </Link>
      {crumbs.map((crumb, index) =>
        crumb.to ? (
          <Link
            key={`crumb-${index}`}
            color="inherit"
            component={RouterLink}
            to={crumb.to}
            data-testid={`crumb-${index}`}
          >
            {crumb.text}
          </Link>
        ) : (
          <Box data-testid={`crumb-${index}`} key={`crumb-${index}`}>
            {crumb.text}
          </Box>
        )
      )}
    </Bread>
  );
};

export default Breadcrumbs;
