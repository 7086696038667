import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatFetchSucceeded, sbatNotFound } from '../actions';
import { SBAT_FETCH_REQUESTED } from '../actionTypes';

function* fetchSbat(action) {
  try {
    const sbat = yield call(api.fetchSbat, action.id);
    yield put(sbatFetchSucceeded({ sbat }));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(sbatNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchSbatSaga() {
  yield takeLatest(SBAT_FETCH_REQUESTED, fetchSbat);
}

export default fetchSbatSaga;
