import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { trainingPlanOptionsFetchSucceeded } from '../actions';
import { TRAINING_PLAN_OPTIONS_FETCH_REQUESTED } from '../actionTypes';

function* fetchTrainingPlanOptions() {
  const res = yield call(api.fetchTrainingPlanOptions);
  yield put(trainingPlanOptionsFetchSucceeded(res));
}

function* fetchTrainingPlanOptionsSaga() {
  yield takeLatest(
    TRAINING_PLAN_OPTIONS_FETCH_REQUESTED,
    fetchTrainingPlanOptions
  );
}

export default fetchTrainingPlanOptionsSaga;
