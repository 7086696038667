import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { usersFetchSucceeded } from '../users/actions';
import { USERS_FETCH_REQUESTED } from '../users/actionTypes';

function* fetchUsers(action) {
  const res = yield call(api.fetchUsers, action.query);
  yield put(usersFetchSucceeded(res));
}

function* fetchUsersSaga() {
  yield takeLatest(USERS_FETCH_REQUESTED, fetchUsers);
}

export default fetchUsersSaga;
