import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

import AutoCompleteListBox from './AutoCompleteListBox';

const useStyles = makeStyles((theme) => ({
  required: {
    width: '100%',
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: theme.palette.required.border,
      },
    },
  },
}));

const AutoCompleteInput = ({
  options,
  label,
  name,
  control,
  hasError,
  errorText,
  defaultValue,
  rules = { required: true },
  freeSolo,
  autoSelect,
  disabled = false,
  requiredOnSubmit = false,
}) => {
  const classes = useStyles();
  return (
    <Controller
      rules={rules}
      as={
        <Autocomplete
          options={options}
          getOptionLabel={(option) => (option.name && option.name) || option} // make compatible with freeSolo
          ListboxComponent={AutoCompleteListBox}
          ListboxProps={{ itemSize: 36 }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={requiredOnSubmit ? classes.required : ''}
              label={label}
              variant="outlined"
              fullWidth
              error={hasError}
              helperText={errorText}
            />
          )}
        />
      }
      onChange={([, data]) => data}
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      freeSolo={freeSolo}
      autoSelect={autoSelect}
      disabled={disabled}
    />
  );
};

export default AutoCompleteInput;
