import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { qualificationFetchSucceeded, qualificationNotFound } from '../actions';
import { QUALIFICATION_FETCH_REQUESTED } from '../actionTypes';

function* fetchQualification(action) {
  try {
    const qualification = yield call(api.fetchQualification, action.id);
    yield put(qualificationFetchSucceeded({ qualification }));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(qualificationNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchQualificationSaga() {
  yield takeLatest(QUALIFICATION_FETCH_REQUESTED, fetchQualification);
}

export default fetchQualificationSaga;
