export const SCHOOLS_FETCH_REQUESTED = 'SCHOOLS_FETCH_REQUESTED';
export const SCHOOLS_FETCH_SUCCEEDED = 'SCHOOLS_FETCH_SUCCEEDED';
export const UPDATE_SCHOOLS_QUERY_SORT = 'UPDATE_SCHOOLS_QUERY_SORT';
export const UPDATE_SCHOOLS_QUERY_SEARCH = 'UPDATE_SCHOOLS_QUERY_SEARCH';
export const UPDATE_SCHOOLS_QUERY_FILTER = 'UPDATE_SCHOOLS_QUERY_FILTER';
export const RESET_SCHOOLS_QUERY = 'RESET_SCHOOLS_QUERY';

export const SCHOOL_FETCH_REQUESTED = 'SCHOOL_FETCH_REQUESTED';
export const SCHOOL_FETCH_SUCCEEDED = 'SCHOOL_FETCH_SUCCEEDED';
export const SCHOOL_NOT_FOUND = 'SCHOOL_NOT_FOUND';

export const SCHOOL_SAVE_REQUESTED = 'SCHOOL_SAVE_REQUESTED';
export const SCHOOL_SAVE_SUCCEEDED = 'SCHOOL_SAVE_SUCCEEDED';
