import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useDispatch } from 'react-redux';

const ResourceTableHeadLabel = ({ header, sort, updateResourceQuerySort }) => {
  const dispatch = useDispatch();
  const isActive = sort.by === header.id;

  const toggleSort = () => {
    let toggleAsc = true;
    if (isActive && sort.asc) {
      toggleAsc = false;
    }
    dispatch(updateResourceQuerySort(header.id, toggleAsc));
  };

  return header.sortable ? (
    <TableSortLabel
      active={isActive}
      direction={isActive && !sort.asc ? 'desc' : 'asc'}
      onClick={toggleSort}
    >
      {header.label}
    </TableSortLabel>
  ) : (
    <>{header.label}</>
  );
};

export default ResourceTableHeadLabel;
