export const QUALIFICATIONS_FETCH_REQUESTED = 'QUALIFICATIONS_FETCH_REQUESTED';
export const QUALIFICATIONS_FETCH_SUCCEEDED = 'QUALIFICATIONS_FETCH_SUCCEEDED';
export const UPDATE_QUALIFICATIONS_QUERY_SORT = 'UPDATE_QUALIFICATIONS_QUERY_SORT';
export const UPDATE_QUALIFICATIONS_QUERY_SEARCH = 'UPDATE_QUALIFICATIONS_QUERY_SEARCH';
export const UPDATE_QUALIFICATIONS_QUERY_FILTER = 'UPDATE_QUALIFICATIONS_QUERY_FILTER';
export const RESET_QUALIFICATIONS_QUERY = 'RESET_QUALIFICATIONS_QUERY';

export const QUALIFICATION_FETCH_REQUESTED = 'QUALIFICATION_FETCH_REQUESTED';
export const QUALIFICATION_FETCH_SUCCEEDED = 'QUALIFICATION_FETCH_SUCCEEDED';
export const QUALIFICATION_NOT_FOUND = 'QUALIFICATION_NOT_FOUND';

export const QUALIFICATION_SAVE_REQUESTED = 'QUALIFICATION_SAVE_REQUESTED';
export const QUALIFICATION_SAVE_SUCCEEDED = 'QUALIFICATION_SAVE_SUCCEEDED';
export const QUALIFICATION_SAVE_FAILED = 'QUALIFICATION_SAVE_FAILED';
