import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: grey[600],
  },
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const TrainingPlanSection = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{title}</Box>
      <Box className={classes.section}>
        {children.map((child, index) => (
          <Box key={index}>{child}</Box>
        ))}
      </Box>
    </Box>
  );
};

export default TrainingPlanSection;
