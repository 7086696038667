import {
  SCHOOLS_FETCH_REQUESTED,
  SCHOOLS_FETCH_SUCCEEDED,
  SCHOOL_FETCH_REQUESTED,
  SCHOOL_FETCH_SUCCEEDED,
  SCHOOL_NOT_FOUND,
  SCHOOL_SAVE_REQUESTED,
  SCHOOL_SAVE_SUCCEEDED,
  UPDATE_SCHOOLS_QUERY_SORT,
  UPDATE_SCHOOLS_QUERY_SEARCH,
  UPDATE_SCHOOLS_QUERY_FILTER,
  RESET_SCHOOLS_QUERY,
} from './actionTypes';

export const schoolsFetchRequested = (query) => ({
  type: SCHOOLS_FETCH_REQUESTED,
  query,
});

export const updateSchoolsQuerySort = (id, asc) => ({
  type: UPDATE_SCHOOLS_QUERY_SORT,
  id,
  asc,
});

export const updateSchoolsQuerySearch = (search) => ({
  type: UPDATE_SCHOOLS_QUERY_SEARCH,
  search,
});

export const updateSchoolsQueryFilter = (filterId, optionValue) => ({
  type: UPDATE_SCHOOLS_QUERY_FILTER,
  filterId,
  optionValue,
});

export const resetSchoolsQuery = () => ({
  type: RESET_SCHOOLS_QUERY,
});

export const schoolsFetchSucceeded = ({ page, next = null }) => ({
  type: SCHOOLS_FETCH_SUCCEEDED,
  schools: page,
  next,
});

export const schoolFetchRequested = (id) => ({
  type: SCHOOL_FETCH_REQUESTED,
  id,
});

export const schoolFetchSucceeded = ({ school }) => ({
  type: SCHOOL_FETCH_SUCCEEDED,
  school,
});

export const schoolNotFound = () => ({
  type: SCHOOL_NOT_FOUND,
});

export const schoolSaveRequested = ({ id, data }) => ({
  type: SCHOOL_SAVE_REQUESTED,
  id,
  data,
});

export const schoolSaveSucceeded = ({ school }) => ({
  type: SCHOOL_SAVE_SUCCEEDED,
  school,
});
