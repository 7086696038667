import React from 'react';

import DetailsList from '../DetailsList';

const StudentDetails = ({ student }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Name',
          text: student.full_name,
        },
        {
          label: 'School',
          text: student.school.name,
        },
      ]}
    />
  );
};

export default StudentDetails;
