import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { contactsFetchSucceeded } from '../actions';
import { CONTACTS_FETCH_REQUESTED } from '../actionTypes';

function* fetchContacts(action) {
  const res = yield call(api.fetchContacts, action.query);
  yield put(contactsFetchSucceeded(res));
}

function* fetchContactsSaga() {
  yield takeLatest(CONTACTS_FETCH_REQUESTED, fetchContacts);
}

export default fetchContactsSaga;
