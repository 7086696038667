import React from 'react';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  required: {
    width: '100%',
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: theme.palette.required.border,
      },
    },
  },
}));

const DateInput = ({
  defaultValue,
  control,
  hasError,
  errorText,
  name,
  label,
  disabled = false,
  rules = {},
  minDate,
  maxDate,
  requiredOnSubmit = false,
}) => {
  const classes = useStyles();

  return (
    <Controller
      as={
        <KeyboardDatePicker
          autoOk
          classes={{ root: requiredOnSubmit ? classes.required : classes.root }}
          inputVariant="outlined"
          variant="inline"
          placeholder="dd/mm/yyyy"
          format="dd/MM/yyyy"
          error={hasError}
          helperText={errorText}
          invalidDateMessage=""
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage=""
          maxDateMessage=""
        />
      }
      label={label}
      name={name}
      defaultValue={defaultValue || null}
      control={control}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default DateInput;
