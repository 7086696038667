import {
  USERS_FETCH_REQUESTED,
  USERS_FETCH_SUCCEEDED,
  UPDATE_USERS_QUERY_SORT,
  UPDATE_USERS_QUERY_SEARCH,
  UPDATE_USERS_QUERY_FILTER,
  RESET_USERS_QUERY,
  USER_FETCH_REQUESTED,
  USER_FETCH_SUCCEEDED,
  USER_NOT_FOUND,
  USER_SAVE_REQUESTED,
  USER_SAVE_SUCCEEDED,
  PENDING_USERS_FETCH_REQUESTED,
  PENDING_USERS_FETCH_SUCCEEDED,
  SET_USERS_QUERY,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  users: null,
  query: {
    cursor: null,
    filters: {},
    search: '',
    sort: {
      by: 'username',
      asc: true,
    },
  },
  fetchingUsers: false,
  user: null,
  fetchingUser: false,
  userNotFound: false,
  savingUser: false,
  pendingUsers: null,
  fetchingPendingUsers: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingUsers: true,
      };
    }

    case USERS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingUsers: false,
        users:
          state.users === null
            ? action.users
            : [...state.users, ...action.users], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_USERS_QUERY_SORT: {
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_USERS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case UPDATE_USERS_QUERY_FILTER: {
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {
            ...state.query.filters,
            [action.filterId]: action.optionValue,
          },
        },
      };
    }

    case RESET_USERS_QUERY: {
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case SET_USERS_QUERY: {
      return {
        ...state,
        fetchingUsers: false,
        users: null,
        query: {
          ...initialState.query,
          ...action.query,
        },
      };
    }

    case USER_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingUser: true,
        user: null,
        userNotFound: false,
      };
    }

    case USER_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingUser: false,
        user: action.user,
      };
    }

    case USER_NOT_FOUND: {
      return {
        ...state,
        fetchingUser: false,
        userNotFound: true,
      };
    }

    case USER_SAVE_REQUESTED: {
      return {
        ...state,
        savingUser: true,
      };
    }

    case USER_SAVE_SUCCEEDED: {
      // Need to reload all users if saved
      return {
        ...state,
        users: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingUser: false,
        user: null,
        userNotFound: false,
        pendingUsers: null,
      };
    }

    case PENDING_USERS_FETCH_REQUESTED: {
      return {
        ...state,
        pendingUsers: null,
        fetchingPendingUsers: true,
      };
    }

    case PENDING_USERS_FETCH_SUCCEEDED: {
      return {
        ...state,
        pendingUsers: action.users,
        fetchingPendingUsers: false,
      };
    }

    case AUTH_USER_PROFILE_SAVE_SUCCEEDED: {
      return { ...state, pendingUsers: null };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
