import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

const ResourceTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default ResourceTableRow;
