import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import { authenticated as authenticatedSelector } from '../../redux/auth/selectors';
import { systemUserLoginRequested } from '../../redux/auth/actions';

const useStyles = makeStyles((theme) => ({
  login: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SystemLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authenticated = useSelector(authenticatedSelector);
  const systemLoginRequested = useSelector(
    (state) => state.auth.systemLoginRequested
  );
  const location = useLocation();
  const { register, handleSubmit, errors, setValue } = useForm();

  const onSubmit = (data) => {
    dispatch(systemUserLoginRequested(data.username, data.password));
    setValue('password', '');
  };

  return authenticated ? (
    <Redirect
      to={{
        pathname: '/',
        from: location,
      }}
    />
  ) : (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.login}>
        <Typography component="h1" variant="h5">
          SYSTEM LOGIN
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            inputRef={register({ required: true })}
            error={Boolean(errors.username)}
            helperText={errors.username && 'Enter your username'}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={register({ required: true })}
            error={Boolean(errors.password)}
            helperText={errors.password && 'Enter your password'}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={systemLoginRequested}
            className={classes.submit}
          >
            Log In
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default SystemLogin;
