import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sbatSaveRequested } from '../../redux/sbats/actions';
import Breadcrumbs from '../Breadcrumbs';
import SbatAddForm from './SbatAddForm';

const AddSbat = () => {
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const onSubmit = (data) => {
    dispatch(
      sbatSaveRequested({
        data: {
          student_id: data.student.id,
          employer_id: data.employer.id,
        },
      })
    );
  };

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { text: 'SBATs', to: '/sbats', key: 'sbats' },
          { text: 'Add SBAT', key: 'add-sbat' },
        ]}
      />
      <SbatAddForm
        title="Add SBAT"
        onSubmit={onSubmit}
        disabled={savingSbat}
        initialSbat={{}}
      />
    </>
  );
};

export default AddSbat;
