import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { userFetchSucceeded, userNotFound } from '../actions';
import { USER_FETCH_REQUESTED } from '../actionTypes';

function* fetchUser(action) {
  try {
    const user = yield call(api.fetchUser, action.id);
    yield put(userFetchSucceeded(user));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(userNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchUserSaga() {
  yield takeLatest(USER_FETCH_REQUESTED, fetchUser);
}

export default fetchUserSaga;
