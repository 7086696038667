import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { employerFetchSucceeded, employerNotFound } from '../actions';
import { EMPLOYER_FETCH_REQUESTED } from '../actionTypes';

function* fetchEmployer(action) {
  try {
    const employer = yield call(api.fetchEmployer, action.id);
    yield put(employerFetchSucceeded({ employer }));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(employerNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchEmployerSaga() {
  yield takeLatest(EMPLOYER_FETCH_REQUESTED, fetchEmployer);
}

export default fetchEmployerSaga;
