import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';

import { qualificationFetchRequested } from '../../redux/actions';
import Breadcrumbs from '../Breadcrumbs';
import Error404 from '../errors/Error404';
import TabSections from '../TabSections';
import QualificationDetails from './QualificationDetails';
import ResourceHistory from '../history/ResourceHistory';
import QualificationForm from './QualificationForm';

const Qualification = () => {
  const dispatch = useDispatch();

  const qualification = useSelector((state) => state.qualifications.qualification);
  const qualificationNotFound = useSelector(
    (state) => state.qualifications.qualificationNotFound
  );
  const savingQualification = useSelector((state) => state.qualifications.savingQualification);

  const { id } = useParams();

  // always fetch student when page loads - this prevents stale data
  useEffect(() => {
    dispatch(qualificationFetchRequested(id));
  }, []);

  return qualificationNotFound ? (
    <Error404 />
  ) : (
    qualification && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'Qualifications', to: '/qualifications', key: 'qualifications' },
            { text: qualification.qualification_title, key: 'edit-qualification' },
          ]}
        />
        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <QualificationDetails qualification={qualification} />,
            },
            {
              label: 'Edit',
              icon: <EditIcon />,
              contents: (
                <QualificationForm
                  disabled={savingQualification}
                  initialQualification={qualification}
                />
              ),
            },
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={qualification.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default Qualification;
