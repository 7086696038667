import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatSaveSucceeded, userNotify } from '../actions';
import { UPLOAD_TRAINING_PLAN_REQUESTED } from '../actionTypes';

function* uploadTrainingPlan({ id, file }) {
  yield call(api.uploadTrainingPlan, id, file);
  yield put(sbatSaveSucceeded());
  yield put(userNotify('Training plan updated successfully'));
}

function* uploadTrainingPlanSaga() {
  yield takeLatest(UPLOAD_TRAINING_PLAN_REQUESTED, uploadTrainingPlan);
}

export default uploadTrainingPlanSaga;
