import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import { userSaveSucceeded, userNotify } from '../actions';
import { USER_SAVE_REQUESTED } from '../actionTypes';

function* saveUser({ id, data }) {
  const user = yield call(api.saveUser, id, data);
  // update state and redirect
  yield put(userSaveSucceeded({ user }));
  yield put(userNotify('User saved successfully'));
  yield put(push('/users'));
}

function* saveUserSaga() {
  yield takeLatest(USER_SAVE_REQUESTED, saveUser);
}

export default saveUserSaga;
