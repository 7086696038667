import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { schoolFetchSucceeded, schoolNotFound } from '../actions';
import { SCHOOL_FETCH_REQUESTED } from '../actionTypes';

function* fetchSchool(action) {
  try {
    const school = yield call(api.fetchSchool, action.id);
    yield put(schoolFetchSucceeded({ school }));
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(schoolNotFound());
        return;
      }
    }

    throw e;
  }
}

function* fetchSchoolSaga() {
  yield takeLatest(SCHOOL_FETCH_REQUESTED, fetchSchool);
}

export default fetchSchoolSaga;
