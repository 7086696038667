export const EMPLOYERS_FETCH_REQUESTED = 'EMPLOYERS_FETCH_REQUESTED';
export const EMPLOYERS_FETCH_SUCCEEDED = 'EMPLOYERS_FETCH_SUCCEEDED';
export const UPDATE_EMPLOYERS_QUERY_SORT = 'UPDATE_EMPLOYERS_QUERY_SORT';
export const UPDATE_EMPLOYERS_QUERY_SEARCH = 'UPDATE_EMPLOYERS_QUERY_SEARCH';
export const RESET_EMPLOYERS_QUERY = 'RESET_EMPLOYERS_QUERY';

export const EMPLOYER_FETCH_REQUESTED = 'EMPLOYER_FETCH_REQUESTED';
export const EMPLOYER_FETCH_SUCCEEDED = 'EMPLOYER_FETCH_SUCCEEDED';
export const EMPLOYER_NOT_FOUND = 'EMPLOYER_NOT_FOUND';

export const EMPLOYER_SAVE_REQUESTED = 'EMPLOYER_SAVE_REQUESTED';
export const EMPLOYER_SAVE_SUCCEEDED = 'EMPLOYER_SAVE_SUCCEEDED';
export const EMPLOYER_SAVE_FAILED = 'EMPLOYER_SAVE_FAILED';
