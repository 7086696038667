import {
  QUALIFICATIONS_FETCH_REQUESTED,
  QUALIFICATIONS_FETCH_SUCCEEDED,
  QUALIFICATION_FETCH_REQUESTED,
  QUALIFICATION_FETCH_SUCCEEDED,
  QUALIFICATION_NOT_FOUND,
  QUALIFICATION_SAVE_REQUESTED,
  QUALIFICATION_SAVE_SUCCEEDED,
  QUALIFICATION_SAVE_FAILED,
  UPDATE_QUALIFICATIONS_QUERY_SORT,
  UPDATE_QUALIFICATIONS_QUERY_SEARCH,
  UPDATE_QUALIFICATIONS_QUERY_FILTER,
  RESET_QUALIFICATIONS_QUERY,
} from './actionTypes';

export const qualificationsFetchRequested = (query) => ({
  type: QUALIFICATIONS_FETCH_REQUESTED,
  query,
});

export const updateQualificationsQuerySort = (id, asc) => ({
  type: UPDATE_QUALIFICATIONS_QUERY_SORT,
  id,
  asc,
});

export const updateQualificationsQuerySearch = (search) => ({
  type: UPDATE_QUALIFICATIONS_QUERY_SEARCH,
  search,
});

export const updateQualificationsQueryFilter = (filterId, optionValue) => ({
  type: UPDATE_QUALIFICATIONS_QUERY_FILTER,
  filterId,
  optionValue,
});

export const resetQualificationsQuery = () => ({
  type: RESET_QUALIFICATIONS_QUERY,
});

export const qualificationsFetchSucceeded = ({ page, next = null }) => ({
  type: QUALIFICATIONS_FETCH_SUCCEEDED,
  qualifications: page,
  next,
});

export const qualificationFetchRequested = (id) => ({
  type: QUALIFICATION_FETCH_REQUESTED,
  id,
});

export const qualificationFetchSucceeded = ({ qualification }) => ({
  type: QUALIFICATION_FETCH_SUCCEEDED,
  qualification,
});

export const qualificationNotFound = () => ({
  type: QUALIFICATION_NOT_FOUND,
});

export const qualificationSaveRequested = ({ id, data }) => ({
  type: QUALIFICATION_SAVE_REQUESTED,
  id,
  data,
});

export const qualificationSaveSucceeded = ({ qualification }) => ({
  type: QUALIFICATION_SAVE_SUCCEEDED,
  qualification,
});

export const qualificationSaveFailed = ({ errors } = {}) => ({
  type: QUALIFICATION_SAVE_FAILED,
  errors,
});
