import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';

const ResourceTableSearch = ({
  updateResourceQuerySearch,
  searchTerm,
  searchLabel,
}) => {
  const dispatch = useDispatch();
  // We keep a local copy of search term and only trigger a real update
  // after waiting a sufficient amount of time using debounce.
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  const [debouncedLocalSearchTerm] = useDebounce(localSearchTerm, 300);

  useEffect(() => {
    if (debouncedLocalSearchTerm === searchTerm) {
      return;
    }

    dispatch(updateResourceQuerySearch(debouncedLocalSearchTerm));
  }, [debouncedLocalSearchTerm]);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  return (
    <TextField
      onChange={(e) => setLocalSearchTerm(e.target.value)}
      type="search"
      label={searchLabel}
      variant="outlined"
      value={localSearchTerm}
      fullWidth
    />
  );
};

export default ResourceTableSearch;
