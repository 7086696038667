export const USERS_FETCH_REQUESTED = 'USERS_FETCH_REQUESTED';
export const USERS_FETCH_SUCCEEDED = 'USERS_FETCH_SUCCEEDED';
export const UPDATE_USERS_QUERY_SORT = 'UPDATE_USERS_QUERY_SORT';
export const UPDATE_USERS_QUERY_SEARCH = 'UPDATE_USERS_QUERY_SEARCH';
export const UPDATE_USERS_QUERY_FILTER = 'UPDATE_USERS_QUERY_FILTER';
export const RESET_USERS_QUERY = 'RESET_USERS_QUERY';

export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED';
export const USER_FETCH_SUCCEEDED = 'USER_FETCH_SUCCEEDED';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';

export const USER_SAVE_REQUESTED = 'USER_SAVE_REQUESTED';
export const USER_SAVE_SUCCEEDED = 'USER_SAVE_SUCCEEDED';

export const PENDING_USERS_FETCH_REQUESTED = 'PENDING_USERS_FETCH_REQUESTED';
export const PENDING_USERS_FETCH_SUCCEEDED = 'PENDING_USERS_FETCH_SUCCEEDED';

export const REDIRECT_TO_USERS_WITH_QUERY = 'REDIRECT_TO_USERS_WITH_QUERY';
export const SET_USERS_QUERY = 'SET_USERS_QUERY';
