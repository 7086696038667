import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatsFetchSucceeded } from '../actions';
import { SBATS_FETCH_REQUESTED } from '../actionTypes';

function* fetchSbats(action) {
  const res = yield call(api.fetchSbats, action.query);
  yield put(sbatsFetchSucceeded(res));
}

function* fetchSbatsSaga() {
  yield takeLatest(SBATS_FETCH_REQUESTED, fetchSbats);
}

export default fetchSbatsSaga;
