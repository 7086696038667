import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import config from '../../config';
import CenteredContainer from '../CenteredContainer';

const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginBottom: theme.spacing(3),
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  bodyText: {
    marginBottom: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.titleBox}>
        <Typography variant="h4">
          SBAT - School Based Apprenticeships & Traineeships
        </Typography>
      </Box>
      <CenteredContainer>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          className={classes.loginButton}
          href={`${config.apiBaseUrl}/saml2/sbat/login`}
          fullWidth
        >
          Login via DOE
        </Button>
        <Typography className={classes.bodyText} variant="body1">
          <em>Don't have an account?</em>
        </Typography>
        <Typography variant="body1">
          <em>
            Login to the NSW DOE SSO by clicking the button above to start the
            registration process.
          </em>
        </Typography>
      </CenteredContainer>
    </Box>
  );
};

export default Login;
