import * as yup from 'yup';

const validDate = (value) => {
  if (!value) {
    return true;
  }

  return value.invalid === null;
};

/**
 * test `value` is after `field`
 * true if either value is empty or invalid
 */
const afterDate = (value, field) => {
  if (!value || !field) {
    return true;
  }
  if (field.invalid !== null) {
    return true;
  }
  return validDate(value) && value >= field;
}

/**
 * test `value` is between `field1` and `field2`
 * true if any value is empty or invalid
 */
const betweenDate = (value, field1, field2) => {
  if (!value || !field1 || !field2) {
    return true;
  }
  if (field1.invalid !== null || field2.invalid !== null) {
    return true;
  }
  return validDate(value) && value >= field1 && value <= field2;
}

export const stringValidation = {
  getTypeSchema: () => {
    return yup.string().nullable();
  },
  getValidationSchema: () => {
    return yup.string().max(255, 'Maximum 255 characters');
  },
};

export const dateValidation = {
  getTypeSchema: () => {
    return yup.object();
  },
  getValidationSchema: () => {
    return yup
      .object()
      .test('valid-date', 'Must be a valid date', validDate)
      .nullable();
  },
};

export const dateAfterValidation = (field, message = 'Must be after date') => {
  return {
    getTypeSchema: () => {
      return yup.object();
    },
    getValidationSchema: () => {
      return yup
        .object()
        .when('$save', {
          is: false,
          then: yup.object().test(
            'valid-date',
            message,
            function (value) {
              const ref = yup.ref(field);
              return afterDate(value, this.resolve(ref));
            }
          )
        })
        .nullable();
    },
  }
};

export const dateBetweenValidation = (field1, field2, message = 'Must be between dates') => {
  return {
    getTypeSchema: () => {
      return yup.object();
    },
    getValidationSchema: () => {
      return yup
        .object()
        .when('$save', {
          is: false,
          then: yup.object().test(
            'valid-date',
            message,
            function (value) {
              const ref1 = yup.ref(field1);
              const ref2 = yup.ref(field2);
              return betweenDate(value, this.resolve(ref1), this.resolve(ref2));
            }
          )
        })
        .nullable();
    },
  }
};

export const textValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup.string().max(1000, 'Maximum 1000 characters');
  },
};

export const postcodeValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup.string().matches(/^(\d{4})$/, {
      excludeEmptyString: true,
      message: 'Postcode number must be 4 digits',
    });
  },
};

export const phoneNumberValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup.string().matches(/^(\d{10})$/, {
      excludeEmptyString: true,
      message: 'Phone number must be 10 digits',
    });
  },
};

// export const yearValidation = {
//   getTypeSchema: () => {
//     return yup.string();
//   },
//   getValidationSchema: () => {
//     return yup
//       .string()
//       .matches(/^(\d{4})$/, {
//         excludeEmptyString: true,
//         message: 'Year must be 4 digits',
//       })
//       .nullable();
//   },
// };

export const schoolYearValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^[1-9]\d*$/, {
        excludeEmptyString: true,
        message: 'Number must be all digits',
      })
      .matches(/^[7-9]|1[0-2]$/, {
        excludeEmptyString: true,
        message: 'Number must be school year between 7-12',
      })
      .nullable();
  },
};


export const emailValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup.string().email().nullable();
  },
};

export const booleanValidation = {
  getTypeSchema: () => {
    return yup.boolean();
  },
  getValidationSchema: () => {
    return yup.boolean();
  },
};

export const objectValidation = {
  getTypeSchema: () => {
    return yup.object();
  },
  getValidationSchema: () => {
    return yup.object().nullable();
  },
};

export const abnValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^(\d{11})$/, {
        excludeEmptyString: true,
        message: 'ABN must be 11 digits',
      })
      .nullable();
  },
};

export const idValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^[1-9]\d*$/, {
        excludeEmptyString: true,
        message: 'ID must be all digits',
      })
      .max(255, 'Maximum 255 characters')
      .nullable();
  },
};

export const idSelectionValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^[1-9]\d*$/, {
        excludeEmptyString: true,
        message: 'Invalid selection',
      })
      .nullable();
  },
};

export const nesaNumberValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^[1-9]\d*$/, {
        excludeEmptyString: true,
        message: 'Number must be all digits',
      })
      .matches(/^\d{6,7}$/, {
        excludeEmptyString: true,
        message: 'Number must be 6-7 digits',
      })
      .nullable();
  },
};

export const ernNumberValidation = {
  getTypeSchema: () => {
    return yup.string();
  },
  getValidationSchema: () => {
    return yup
      .string()
      .matches(/^[1-9]\d*$/, {
        excludeEmptyString: true,
        message: 'Number must be all digits',
      })
      .matches(/^\d{9}$/, {
        excludeEmptyString: true,
        message: 'Number must be exactly 9 digits',
      })
      .nullable();
  },
};

export const arrayValidation = {
  getTypeSchema: () => {
    return yup.array();
  },
  getValidationSchema: () => {
    return yup.array().nullable();
  },
};

export const atLeastOneValidation = (fields, message = 'At least one required') => {
  return {
    getTypeSchema: () => {
      return yup.boolean();
    },
    getValidationSchema: () => {
      return yup.boolean().when('$save', {
        is: false, // only when submitting with validation
        then: yup.boolean().when(fields, {
          is: (...ff) => !ff.some((f) => !!f), // false when all fields are empty
          then: yup.boolean().required(message),
        })
      });
    },
  }
}

const getValidationSchema = (schema) => {
  const validationShape = Object.keys(schema).reduce((carry, fieldKey) => {
    const validation = schema[fieldKey].validation;
    const validationSchema = validation.getValidationSchema();
    carry[fieldKey] = validation.getTypeSchema().when('$save', {
      is: true,
      then: validationSchema,
      otherwise: schema[fieldKey].required
        ? validationSchema.required('Required')
        : validationSchema,
    });
    return carry;
  }, {});
  return yup.object().shape(validationShape);
};

export default getValidationSchema;
