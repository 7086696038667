import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formElement: {
    marginBottom: theme.spacing(2),
  },
}));

const Form = ({ onSubmit, disabled, children, submitText }) => {
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {React.Children.map(
        children,
        (child) =>
          child && (
            <Box key={child.key} className={classes.formElement}>
              {child}
            </Box>
          )
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        disabled={disabled}
        className={classes.submit}
      >
        {submitText}
      </Button>
    </form>
  );
};

export default Form;
