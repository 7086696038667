import { useSelector } from 'react-redux';
import {
  ROLE_ID_NO_ROLE,
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from '../roles';

const getSchoolsUserCanView = (schools, user) => {
  return schools.filter((school) => {
    switch (user.role.id) {
      case ROLE_ID_NO_ROLE:
        return false;
      case ROLE_ID_SCHOOL:
        return user.school.id === school.id;
      case ROLE_ID_SBAT_OFFICER:
      case ROLE_ID_ADMIN:
      case ROLE_ID_SYSTEM:
        return true;
      default:
        return false;
    }
  });
};

const useSchools = () => {
  const authUser = useSelector((state) => state.auth.user);
  const schools = useSelector((state) => state.apiConfig.config.schools);
  const schoolsUserCanView = getSchoolsUserCanView(schools, authUser);

  return {
    schools,
    schoolsUserCanView,
  };
};

export default useSchools;
