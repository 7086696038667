import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';

import CenteredForm from '../form/CenteredForm';
import SelectInput from '../form/SelectInput';
import AutoCompleteInput from '../form/AutoCompleteInput';
import { authUserRegisterRequested } from '../../redux/actions';
import { ROLE_ID_SCHOOL, ROLE_ID_SBAT_OFFICER } from '../../roles';
import useAuthUser from '../../hooks/useAuthUser';

const Register = () => {
  const { control, handleSubmit, errors, watch } = useForm();
  const dispatch = useDispatch();

  const registeringUser = useSelector((state) => state.auth.registeringUser);

  const onSubmit = (data) => {
    dispatch(
      authUserRegisterRequested({
        roleId: data.role_id,
        schoolId: data.school ? data.school.id : null,
        rtoId: data.rto ? data.rto.id : null,
      })
    );
  };

  const roles = useSelector((state) =>
    state.apiConfig.config.user.roles.filter((role) => {
      return [ROLE_ID_SCHOOL, ROLE_ID_SBAT_OFFICER].includes(role.id);
    })
  );

  const schools = useSelector((state) => state.apiConfig.config.schools);
  const rtos = useSelector((state) => state.apiConfig.config.rtos);

  const selectedRoleId = watch('role_id');
  const { authUser } = useAuthUser();
  const defaultSchool = authUser.school
    ? schools.find((school) => {
        return school.id === authUser.school.id;
      })
    : null;
  const defaultRto = authUser.rto
    ? rtos.find((rto) => {
        return rto.id === authUser.rto.id;
      })
    : null;

  return (
    <CenteredForm
      title="Register"
      onSubmit={handleSubmit(onSubmit)}
      submitText="Register"
      disabled={registeringUser}
    >
      <Typography key="register-body-1" variant="body1">
        You have successfully authenticated using your Department of Education
        account.
      </Typography>
      <Typography key="register-body-2" variant="body1">
        To begin the registration process, select an role from the dropdown
        below.
      </Typography>

      <SelectInput
        options={roles}
        name="role_id"
        label="Select a Role"
        key="role_id"
        control={control}
        hasError={Boolean(errors.role_id)}
        errorText={errors.role_id && 'Required'}
      />

      {selectedRoleId === ROLE_ID_SCHOOL && (
        <AutoCompleteInput
          options={schools}
          label="Select a school"
          key="school"
          name="school"
          control={control}
          defaultValue={defaultSchool}
          hasError={Boolean(errors.school)}
          errorText={errors.school && 'Required'}
        />
      )}
      {selectedRoleId === ROLE_ID_SBAT_OFFICER && (
        <AutoCompleteInput
          options={rtos}
          label="Select a TQI team"
          key="rto"
          name="rto"
          control={control}
          defaultValue={defaultRto}
          hasError={Boolean(errors.rto)}
          errorText={errors.rto && 'Required'}
        />
      )}
    </CenteredForm>
  );
};

export default Register;
