import {
  EMPLOYERS_FETCH_REQUESTED,
  EMPLOYERS_FETCH_SUCCEEDED,
  EMPLOYER_FETCH_REQUESTED,
  EMPLOYER_FETCH_SUCCEEDED,
  EMPLOYER_NOT_FOUND,
  EMPLOYER_SAVE_REQUESTED,
  EMPLOYER_SAVE_SUCCEEDED,
  EMPLOYER_SAVE_FAILED,
  UPDATE_EMPLOYERS_QUERY_SORT,
  UPDATE_EMPLOYERS_QUERY_SEARCH,
  RESET_EMPLOYERS_QUERY,
} from './actionTypes';

export const employersFetchRequested = (query) => ({
  type: EMPLOYERS_FETCH_REQUESTED,
  query,
});

export const updateEmployersQuerySort = (id, asc) => ({
  type: UPDATE_EMPLOYERS_QUERY_SORT,
  id,
  asc,
});

export const updateEmployersQuerySearch = (search) => ({
  type: UPDATE_EMPLOYERS_QUERY_SEARCH,
  search,
});

export const resetEmployersQuery = () => ({
  type: RESET_EMPLOYERS_QUERY,
});

export const employersFetchSucceeded = ({ page, next = null }) => ({
  type: EMPLOYERS_FETCH_SUCCEEDED,
  employers: page,
  next,
});

export const employerFetchRequested = (id) => ({
  type: EMPLOYER_FETCH_REQUESTED,
  id,
});

export const employerFetchSucceeded = ({ employer }) => ({
  type: EMPLOYER_FETCH_SUCCEEDED,
  employer,
});

export const employerNotFound = () => ({
  type: EMPLOYER_NOT_FOUND,
});

export const employerSaveRequested = ({ id, data }) => ({
  type: EMPLOYER_SAVE_REQUESTED,
  id,
  data,
});

export const employerSaveSucceeded = ({ employer }) => ({
  type: EMPLOYER_SAVE_SUCCEEDED,
  employer,
});

export const employerSaveFailed = ({ errors } = {}) => ({
  type: EMPLOYER_SAVE_FAILED,
  errors,
});
