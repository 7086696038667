import { useSelector } from 'react-redux';

const useUserStatuses = () => {
  const userStatuses = useSelector(
    (state) => state.apiConfig.config.user.statuses
  );

  return {
    userStatuses,
  };
};

export default useUserStatuses;
