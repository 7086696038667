import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import { schoolSaveSucceeded, userNotify } from '../actions';
import { SCHOOL_SAVE_REQUESTED } from '../actionTypes';

function* saveSchool({ id, data }) {
  const school = yield call(api.saveSchool, id, data);
  // update state and redirect
  yield put(schoolSaveSucceeded({ school }));
  yield put(userNotify('School saved successfully'));
  yield put(push('/schools'));
}

function* saveSchoolSaga() {
  yield takeLatest(SCHOOL_SAVE_REQUESTED, saveSchool);
}

export default saveSchoolSaga;
