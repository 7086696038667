import React from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import grey from '@material-ui/core/colors/grey';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { SBAT_ACTION_SUBMIT } from '../../../sbatActions';

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed',
    borderColor: grey[400],
    color: grey[400],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  uploadText: {
    color: grey[500],
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: grey[600],
  },
  trainingPlanBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  noTrainingPlanBox: {
    color: theme.palette.error.main,
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
  uploadedFileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  downloadTrainingPlan: {
    textDecoration: 'none',
  },
  uploadError: {
    color: theme.palette.error.main,
  },
  progressBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadBox: {
    marginBottom: theme.spacing(3),
  },
}));

const TrainingPlanUpload = ({
  sbat,
  trainingPlanDownloadUrl = null,
  uploadTrainingPlanRequestedAction,
  token = null,
  sbatSaveRequestedAction,
}) => {
  const classes = useStyles();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxSize: 10000000, // 10 MB
    accept: ['application/pdf'],
  });
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const file = acceptedFiles[0] ? acceptedFiles[0] : null;
  const fileRejection = fileRejections[0] ? fileRejections[0] : null;

  const uploadTrainingPlan = () => {
    dispatch(
      uploadTrainingPlanRequestedAction({
        id: sbat.id,
        file,
        token,
      })
    );
  };

  const progressSbat = () => {
    dispatch(
      sbatSaveRequestedAction({
        id: sbat.id,
        action: SBAT_ACTION_SUBMIT,
        token,
      })
    );
  };

  return (
    <Box>
      <Box className={classes.trainingPlanBox}>
        <Box className={classes.title}>Training Plan</Box>
        {trainingPlanDownloadUrl ? (
          <a
            href={trainingPlanDownloadUrl}
            className={classes.downloadTrainingPlan}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary">
              <CloudDownloadIcon className={classes.downloadIcon} /> Download
            </Button>
          </a>
        ) : (
          <Box className={classes.noTrainingPlanBox}>
            This SBAT has no training plan
          </Box>
        )}
      </Box>

      <Box className={classes.uploadBox}>
        <Box>
          <Box className={classes.title}>Upload New Version</Box>
          <div
            {...getRootProps({
              className: classes.fileUpload,
            })}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon fontSize="large" />
            <div className={classes.uploadText}>
              Drop a file here, or click to browse
            </div>
          </div>
        </Box>
        <Box className={classes.uploadedFileBox}>
          <Box>
            {file && <Box>{file.name}</Box>}
            {fileRejection && (
              <Box className={classes.uploadError}>
                {fileRejection.errors[0].message}
              </Box>
            )}
          </Box>
          <Box>
            <Button
              disabled={!file}
              variant="contained"
              color="secondary"
              onClick={(e) => uploadTrainingPlan()}
            >
              Update Training Plan
            </Button>
          </Box>
        </Box>
      </Box>

      {sbat.can_progress && (
        <Box className={classes.progressBox}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={(e) => progressSbat()}
            disabled={savingSbat}
            className={classes.submit}
          >
            Progress
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TrainingPlanUpload;
