import React, { useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch } from 'react-redux';

import ResourceTableCell from './ResourceTableCell';
import ResourceTableRow from './ResourceTableRow';

/* eslint-disable react/no-array-index-key */

const ResourceTableBody = ({
  columnCount,
  resources,
  requestResourceFetch,
  isFetching,
  mapResourceToColumns,
  resourceQuery,
}) => {
  const dispatch = useDispatch();

  function handleScroll() {
    const errorMargin = 100; // px, Firefox does not reliable trigger at the absolute end of the page.
    if (
      window.innerHeight + document.documentElement.scrollTop <=
      document.documentElement.offsetHeight - errorMargin
    ) {
      return;
    }

    // reached end of resources
    if (!resourceQuery.cursor) {
      return;
    }
    dispatch(requestResourceFetch(resourceQuery));
  }

  useEffect(() => {
    // Remove any old scroll handler
    window.removeEventListener('scroll', handleScroll);

    window.addEventListener('scroll', handleScroll);

    // clean up scroll handler
    return () => window.removeEventListener('scroll', handleScroll);
  }, [resourceQuery]);

  return (
    <TableBody>
      {resources &&
        resources.map((resource) => {
          return (
            <ResourceTableRow hover key={`resource-${resource.id}`}>
              {mapResourceToColumns(resource).map((resourceCol, index) => (
                <ResourceTableCell key={`col-${resource.id}-${index}`}>
                  {resourceCol.label}
                </ResourceTableCell>
              ))}
            </ResourceTableRow>
          );
        })}
      {(!resources || isFetching) &&
        [...Array(10)].map((row, index) => {
          return (
            <TableRow key={`placeholder-${index}`}>
              <TableCell colSpan={columnCount}>
                <Skeleton variant="rect" height={100} />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default ResourceTableBody;
