import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'auto',
  },
  userButton: {
    marginBottom: theme.spacing(1),
    maxWidth: '200px',
    minWidth: '200px',
  },
}));

const ResourceTableButtonGroup = ({ buttons }) => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonBox}>
      {buttons.map((button) => (
        <Button
          key={button.key}
          className={classes.userButton}
          component={button.to ? Link : undefined}
          to={button.to}
          onClick={button.onClick}
          variant="outlined"
          color={button.color}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
};

export default ResourceTableButtonGroup;
