export const STUDENTS_FETCH_REQUESTED = 'STUDENTS_FETCH_REQUESTED';
export const STUDENTS_FETCH_SUCCEEDED = 'STUDENTS_FETCH_SUCCEEDED';
export const UPDATE_STUDENTS_QUERY_SORT = 'UPDATE_STUDENTS_QUERY_SORT';
export const UPDATE_STUDENTS_QUERY_SEARCH = 'UPDATE_STUDENTS_QUERY_SEARCH';
export const UPDATE_STUDENTS_QUERY_FILTER = 'UPDATE_STUDENTS_QUERY_FILTER';
export const RESET_STUDENTS_QUERY = 'RESET_STUDENTS_QUERY';

export const STUDENT_FETCH_REQUESTED = 'STUDENT_FETCH_REQUESTED';
export const STUDENT_FETCH_SUCCEEDED = 'STUDENT_FETCH_SUCCEEDED';
export const STUDENT_NOT_FOUND = 'STUDENT_NOT_FOUND';

export const STUDENT_SAVE_REQUESTED = 'STUDENT_SAVE_REQUESTED';
export const STUDENT_SAVE_SUCCEEDED = 'STUDENT_SAVE_SUCCEEDED';
export const STUDENT_SAVE_FAILED = 'STUDENT_SAVE_FAILED';
