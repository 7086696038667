import React from 'react';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const ResourceTableCheckBoxFilter = ({
  filter,
  checked,
  updateResourceQueryFilter,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectOption = (event) => {
    dispatch(updateResourceQueryFilter(filter.id, event.target.checked));
  };

  return (
    <Box className={classes.root}>
      {' '}
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              selectOption(e);
            }}
            checked={checked || false}
            name={filter.id}
            color="secondary"
          />
        }
        label={filter.label}
      />
    </Box>
  );
};

export default ResourceTableCheckBoxFilter;
