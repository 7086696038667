import {
  QUALIFICATIONS_FETCH_REQUESTED,
  QUALIFICATIONS_FETCH_SUCCEEDED,
  QUALIFICATION_FETCH_REQUESTED,
  QUALIFICATION_FETCH_SUCCEEDED,
  QUALIFICATION_NOT_FOUND,
  QUALIFICATION_SAVE_REQUESTED,
  QUALIFICATION_SAVE_SUCCEEDED,
  QUALIFICATION_SAVE_FAILED,
  UPDATE_QUALIFICATIONS_QUERY_SORT,
  UPDATE_QUALIFICATIONS_QUERY_SEARCH,
  UPDATE_QUALIFICATIONS_QUERY_FILTER,
  RESET_QUALIFICATIONS_QUERY,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  qualifications: null,
  fetchingQualifications: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'qualification_title',
      asc: true,
    },
  },
  qualification: null,
  fetchingQualification: false,
  savingQualification: false,
  saveErrors: [],
  qualificationNotFound: false,
};

const qualificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUALIFICATIONS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingQualifications: true,
      };
    }

    case QUALIFICATIONS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingQualifications: false,
        qualifications:
          state.qualifications === null
            ? action.qualifications
            : [...state.qualifications, ...action.qualifications], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_QUALIFICATIONS_QUERY_SORT: {
      return {
        ...state,
        fetchingQualifications: false,
        qualifications: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_QUALIFICATIONS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingQualifications: false,
        qualifications: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case UPDATE_QUALIFICATIONS_QUERY_FILTER: {
      return {
        ...state,
        fetchingQualifications: false,
        qualifications: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {
            ...state.query.filters,
            [action.filterId]: action.optionValue,
          },
        },
      };
    }

    case RESET_QUALIFICATIONS_QUERY: {
      return {
        ...state,
        fetchingQualifications: false,
        qualifications: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case QUALIFICATION_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingQualification: true,
        qualification: null,
        qualificationNotFound: false,
      };
    }

    case QUALIFICATION_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingQualification: false,
        qualification: action.qualification,
      };
    }

    case QUALIFICATION_NOT_FOUND: {
      return {
        ...state,
        fetchingQualification: false,
        qualificationNotFound: true,
      };
    }

    case QUALIFICATION_SAVE_REQUESTED: {
      return {
        ...state,
        savingQualification: true,
      };
    }

    case QUALIFICATION_SAVE_SUCCEEDED: {
      // Need to reload all qualifications if saved
      return {
        ...state,
        qualifications: null,
        qualification: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingQualification: false,
        saveErrors: [],
      };
    }

    case QUALIFICATION_SAVE_FAILED: {
      return {
        ...state,
        saveErrors: action.errors ?? [],
        savingQualification: false,
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default qualificationsReducer;
