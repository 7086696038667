import React from 'react';

import useAuthUser from '../../hooks/useAuthUser';
import DetailsList from '../DetailsList';

const ProfileDetails = () => {
  const { authUser } = useAuthUser();

  return (
    <DetailsList
      details={[
        {
          label: 'Username',
          text: authUser.username,
        },
        {
          label: 'First Name',
          text: authUser.name.first,
        },
        {
          label: 'Last Name',
          text: authUser.name.last,
        },
        {
          label: 'Role',
          text: authUser.role.name,
        },
        {
          label: 'Status',
          text: authUser.status.name,
        },
      ]}
    />
  );
};

export default ProfileDetails;
