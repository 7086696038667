import React from 'react';
import { useForm } from 'react-hook-form';

import CenteredForm from '../form/CenteredForm';
import SelectStudent from './SelectStudent';
import SelectEmployer from './SelectEmployer';

const SbatAddForm = ({ title, onSubmit, disabled, initialSbat }) => {
  const { setValue, control, handleSubmit, errors, watch } = useForm();
  return (
    <CenteredForm
      title={title}
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <SelectStudent
        watch={watch}
        setValue={setValue}
        control={control}
        disabled={disabled}
        errors={errors}
      />
      <SelectEmployer control={control} disabled={disabled} errors={errors} />
    </CenteredForm>
  );
};

export default SbatAddForm;
