import { USER_NOTIFY_QUEUE, USER_NOTIFY_DEQUEUE } from '../actionTypes';

const initialState = {
  notifications: [],
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_NOTIFY_QUEUE: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            success: action.success,
            msg: action.msg,
          },
        ],
      };
    }
    case USER_NOTIFY_DEQUEUE: {
      return {
        ...state,
        notifications: state.notifications.slice(1),
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
