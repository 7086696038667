import { actionChannel, call, delay, put, take } from 'redux-saga/effects';
import { userNotifyQueue, userNotifyDequeue } from '../ui/actions';
import { USER_NOTIFY } from '../ui/actionTypes';

// show notification for X seconds
// then pause for Y seconds before showing next notify
function* notifyUserHandler({ msg, success }) {
  yield put(userNotifyQueue(msg, success));
  yield delay(5000);
  yield put(userNotifyDequeue());
  yield delay(500); // pause between notices
}

function* notifyUserSaga() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(USER_NOTIFY);
  while (true) {
    // 2- take from the channel
    const payload = yield take(requestChan);

    // 3- Note that we're using a blocking call
    yield call(notifyUserHandler, payload);
  }
}

export default notifyUserSaga;
