import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { authUserProfileSaveSucceeded, userNotify } from '../actions';
import { AUTH_USER_PROFILE_SAVE_REQUESTED } from '../actionTypes';

function* saveAuthUserProfile({ data }) {
  const user = yield call(api.saveUserProfile, data);
  // update state and redirect
  yield put(authUserProfileSaveSucceeded(user));
  yield put(userNotify('Profile saved successfully'));
}

function* saveAuthUserProfileSaga() {
  yield takeLatest(AUTH_USER_PROFILE_SAVE_REQUESTED, saveAuthUserProfile);
}

export default saveAuthUserProfileSaga;
