import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { pendingSbatsFetchSucceeded } from '../actions';
import { PENDING_SBATS_FETCH_REQUESTED } from '../actionTypes';

function* fetchPendingSbats() {
  const res = yield call(api.fetchPendingSbats);
  yield put(pendingSbatsFetchSucceeded(res));
}

function* fetchPendingSbatsSaga() {
  yield takeLatest(PENDING_SBATS_FETCH_REQUESTED, fetchPendingSbats);
}

export default fetchPendingSbatsSaga;
