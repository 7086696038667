import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(15),
  },
  goBack: {
    marginTop: theme.spacing(2),
  },
}));

const Error403 = () => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h1">403</Typography>
      <Typography variant="h6">
        You do not have the required permissions to access this page or
        resource.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={goBack}
        className={classes.goBack}
      >
        Go back
      </Button>
    </Box>
  );
};

export default Error403;
