import {
  EMPLOYERS_FETCH_REQUESTED,
  EMPLOYERS_FETCH_SUCCEEDED,
  EMPLOYER_SAVE_REQUESTED,
  EMPLOYER_SAVE_SUCCEEDED,
  EMPLOYER_SAVE_FAILED,
  EMPLOYER_FETCH_REQUESTED,
  EMPLOYER_FETCH_SUCCEEDED,
  EMPLOYER_NOT_FOUND,
  UPDATE_EMPLOYERS_QUERY_SORT,
  UPDATE_EMPLOYERS_QUERY_SEARCH,
  RESET_EMPLOYERS_QUERY,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  employers: null,
  fetchingEmployers: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'legal_name',
      asc: true,
    },
  },
  employer: null,
  fetchingEmployer: false,
  savingEmployer: false,
  saveErrors: [],
  employerNotFound: false,
};

const employersReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYERS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingEmployers: true,
      };
    }

    case EMPLOYERS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingEmployers: false,
        employers:
          state.employers === null
            ? action.employers
            : [...state.employers, ...action.employers], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_EMPLOYERS_QUERY_SORT: {
      return {
        ...state,
        fetchingEmployers: false,
        employers: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_EMPLOYERS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingEmployers: false,
        employers: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case RESET_EMPLOYERS_QUERY: {
      return {
        ...state,
        fetchingEmployers: false,
        employers: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case EMPLOYER_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingEmployer: true,
        employer: null,
        employerNotFound: false,
      };
    }

    case EMPLOYER_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingEmployer: false,
        employer: action.employer,
      };
    }

    case EMPLOYER_NOT_FOUND: {
      return {
        ...state,
        fetchingEmployer: false,
        employerNotFound: true,
      };
    }

    case EMPLOYER_SAVE_REQUESTED: {
      return {
        ...state,
        savingEmployer: true,
      };
    }

    case EMPLOYER_SAVE_SUCCEEDED: {
      // Need to reload all employers if saved
      return {
        ...state,
        employers: null,
        employer: null,
        query: {
          ...state.query,
          cursor: null,
        },
        saveErrors: [],
        savingEmployer: false,
      };
    }

    case EMPLOYER_SAVE_FAILED: {
      return {
        ...state,
        saveErrors: action.errors ?? [],
        savingEmployer: false,
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default employersReducer;
