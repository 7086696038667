import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { trainingPlanOptionsFetchRequested } from '../redux/actions';

const useStates = () => {
  const dispatch = useDispatch();

  const trainingPlanOptions = useSelector(
    (state) => state.sbats.trainingPlanOptions
  );

  const fetchingTrainingPlanOptions = useSelector(
    (state) => state.sbats.fetchingTrainingPlanOptions
  );

  useEffect(() => {
    if (!trainingPlanOptions && !fetchingTrainingPlanOptions) {
      dispatch(trainingPlanOptionsFetchRequested());
    }
  }, [trainingPlanOptions, fetchingTrainingPlanOptions]);

  return { trainingPlanOptions };
};

export default useStates;
