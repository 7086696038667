import React from 'react';
import Grid from '@material-ui/core/Grid';
import ResourceTableSelectFilter from './ResourceTableSelectFilter';
import ResourceTableSelectMultiFilter from './ResourceTableSelectMultiFilter';
import AutoCompleteFilter from './AutoCompleteFilter';
import ResourceTableCheckBoxFilter from './ResourceTableCheckBoxFilter';
import {
  FILTER_TYPE_SELECT,
  FILTER_TYPE_SELECT_MULTI,
  FILTER_TYPE_SELECT_AUTOCOMPLETE,
  FILTER_TYPE_CHECKBOX,
} from './filterTypes';

const ResourceTableFilters = ({
  filters,
  selectedOptions,
  updateResourceQueryFilter,
}) => {
  // Group filters into two groups: selects + checkboxes, they
  // will be put in seperate grids

  const groupedFilters = filters.reduce(
    (carry, filter) => {
      if (filter.type === FILTER_TYPE_CHECKBOX) {
        carry.checkboxes.push(filter);
        return carry;
      }
      carry.selects.push(filter);
      return carry;
    },
    {
      selects: [],
      checkboxes: [],
    }
  );
  return (
    <>
      {groupedFilters.selects.length > 0 && (
        <Grid container spacing={3}>
          {groupedFilters.selects.map((filter) => (
            <Grid key={filter.id} item xs={3} sm={3} lg={3}>
              {
                {
                  FILTER_TYPE_SELECT: (
                    <ResourceTableSelectFilter
                      filter={filter}
                      selectedOption={selectedOptions[filter.id]}
                      updateResourceQueryFilter={updateResourceQueryFilter}
                    />
                  ),
                  [FILTER_TYPE_SELECT_MULTI]: (
                    <ResourceTableSelectMultiFilter
                      filter={filter}
                      selectedOptions={selectedOptions[filter.id]}
                      updateResourceQueryFilter={updateResourceQueryFilter}
                    />
                  ),
                  FILTER_TYPE_SELECT_AUTOCOMPLETE: (
                    <AutoCompleteFilter
                      filter={filter}
                      selectedOptionValue={selectedOptions[filter.id]}
                      updateResourceQueryFilter={updateResourceQueryFilter}
                    />
                  ),
                }[filter.type]
              }
            </Grid>
          ))}
        </Grid>
      )}
      {groupedFilters.checkboxes.length > 0 && (
        <Grid container spacing={3}>
          {groupedFilters.checkboxes.map((filter) => (
            <Grid key={filter.id} item xs={3} sm={3} lg={3}>
              <ResourceTableCheckBoxFilter
                filter={filter}
                checked={selectedOptions[filter.id]}
                updateResourceQueryFilter={updateResourceQueryFilter}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ResourceTableFilters;
