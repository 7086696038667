import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import {
  studentSaveSucceeded,
  studentSaveFailed,
  userNotify,
} from '../actions';
import { STUDENT_SAVE_REQUESTED } from '../actionTypes';

function* saveStudent({ id, data }) {
  try {
    const student = id
      ? yield call(api.saveStudent, id, data)
      : yield call(api.createStudent, data);
    // update state and redirect
    yield put(studentSaveSucceeded({ student }));
    yield put(userNotify('Student saved successfully'));
    yield put(push('/students'));
  } catch (e) {
    yield put(userNotify(e?.response?.data?.message ?? 'Error', false));
    if (e?.response?.status === 422 && e.response?.data?.errors) {
      // 422 failed backend validation
      // massage error response to object
      // {[field_name]: {message: 'error message'}}
      const errors = Object.entries(e.response.data.errors).reduce(
        (acc, [key, value]) => {
          acc[key] = { message: value.join('; ') };
          return acc;
        },
        {}
      );
      yield put(studentSaveFailed({ errors }));
    } else {
      yield put(studentSaveFailed());
    }
  }
}

function* saveStudentSaga() {
  yield takeLatest(STUDENT_SAVE_REQUESTED, saveStudent);
}

export default saveStudentSaga;
