import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const ResourceTableCell = withStyles((theme) => ({
  head: {
    padding: theme.spacing(2),
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    fontSize: '16px',
  },
  body: {
    padding: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}))(TableCell);

export default ResourceTableCell;
