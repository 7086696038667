import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import Card from '@material-ui/core/Card';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import RoleColoredTimelineDot from './RoleColoredTimelineDot';

const useStyles = makeStyles((theme) => ({
  activityTime: {
    fontSize: 14,
  },
  activityDate: {
    fontSize: 15,
    fontWeight: 900,
  },
  timelineBox: {
    maxHeight: '600px',
    overflowY: 'auto',
  },
  selectedTimelineItem: {
    backgroundColor: grey[300],
  },
  timelineItem: {
    '&:hover': {
      backgroundColor: grey[200],
      cursor: 'pointer',
    },
  },
  activeItemBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  iconButton: {
    '& svg': {
      fontSize: '75px',
    },
  },
  iconButtonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  changeText: {
    wordWrap: 'break-word',
  },
}));

const ResourceHistory = ({ activities }) => {
  const classes = useStyles();

  const [activeActivityIndex, setActiveActivityIndex] = useState(0);

  const previousDisabled = activeActivityIndex === 0;

  const nextDisabled = activeActivityIndex === activities.length - 1;

  const previousActivity = () => {
    if (previousDisabled) {
      return;
    }

    setActiveActivityIndex(activeActivityIndex - 1);
  };

  const nextActivity = () => {
    if (nextDisabled) {
      return;
    }

    setActiveActivityIndex(activeActivityIndex + 1);
  };

  const activeActivity =
    activeActivityIndex === null ? null : activities[activeActivityIndex];

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Box className={classes.timelineBox}>
          <Timeline align="right">
            {activities.map((activity, index) => (
              <TimelineItem
                onClick={() => setActiveActivityIndex(index)}
                classes={{
                  root:
                    index === activeActivityIndex
                      ? classes.selectedTimelineItem
                      : classes.timelineItem,
                }}
                key={activity.id}
              >
                <TimelineOppositeContent>
                  <Typography color="textSecondary">
                    {activity.description}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <RoleColoredTimelineDot roleId={activity.causer.role.id} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box className={classes.activityDate}>
                    {new Date(activity.created_at).toLocaleDateString()}
                  </Box>
                  <Box className={classes.activityTime}>
                    {new Date(activity.created_at).toLocaleTimeString()}
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Box className={classes.activeItemBox}>
          <Box className={classes.iconButtonBox}>
            <IconButton
              onClick={() => previousActivity()}
              disabled={previousDisabled}
              className={classes.iconButton}
              color="primary"
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
          {activeActivity && (
            <Card variant="outlined">
              <CardHeader
                title={activeActivity.description}
                subheader={
                  <Box>
                    <Box>
                      {new Date(activeActivity.created_at).toLocaleDateString()}{' '}
                      {new Date(activeActivity.created_at).toLocaleTimeString()}
                    </Box>
                    <Box>
                      {activeActivity.causer.name.first &&
                        activeActivity.causer.name.last && (
                          <>
                            {activeActivity.causer.name.first}{' '}
                            {activeActivity.causer.name.last} |{' '}
                          </>
                        )}
                      {activeActivity.causer.username} |{' '}
                      {activeActivity.causer.role.name}
                    </Box>
                  </Box>
                }
              />
              <CardContent>
                {Object.keys(activeActivity.changes).map((attribute) => (
                  <Grid key={attribute} container>
                    <Grid item xs={3}>
                      <strong>{attribute}</strong>
                    </Grid>
                    <Grid className={classes.changeText} item xs={4}>
                      {activeActivity.changes[attribute].old
                        ? activeActivity.changes[attribute].old
                        : 'None'}
                    </Grid>
                    <Grid item xs={1}>
                      <ArrowForwardIcon />
                    </Grid>
                    <Grid className={classes.changeText} item xs={4}>
                      {activeActivity.changes[attribute].new
                        ? activeActivity.changes[attribute].new
                        : 'None'}
                    </Grid>
                  </Grid>
                ))}
              </CardContent>
            </Card>
          )}
          <Box className={classes.iconButtonBox}>
            <IconButton
              onClick={() => nextActivity()}
              disabled={nextDisabled}
              className={classes.iconButton}
              color="primary"
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResourceHistory;
