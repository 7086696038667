import { useSelector } from 'react-redux';

const useStudentGenders = () => {
  const genders = useSelector(
    (state) => state.apiConfig.config.student.genders
  );

  return {
    genders,
  };
};

export default useStudentGenders;
