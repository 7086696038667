import React from 'react';
import AppWrappers from './AppWrappers';
import App from './App';
import store, { history } from './redux/store';

const WrappedApp = () => {
  return (
    <AppWrappers store={store} history={history}>
      <App />
    </AppWrappers>
  );
};

export default WrappedApp;
