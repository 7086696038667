import React from 'react';
import Typography from '@material-ui/core/Typography';

import CenteredContainer from '../CenteredContainer';
import Form from './Form';

const CenteredForm = ({ title, onSubmit, disabled, children, submitText }) => {
  return (
    <CenteredContainer>
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      <Form onSubmit={onSubmit} disabled={disabled} submitText={submitText}>
        {children}
      </Form>
    </CenteredContainer>
  );
};

export default CenteredForm;
