import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(15),
  },
}));

const SbatProgressed = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h1">SBAT Progressed</Typography>
      <Typography variant="h6">
        This SBAT been successfully progressed. This link can no longer be used
        to access the training plan.
      </Typography>
    </Box>
  );
};

export default SbatProgressed;
