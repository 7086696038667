import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  qualificationsFetchRequested,
  updateQualificationsQuerySort,
  updateQualificationsQuerySearch,
  resetQualificationsQuery,
} from '../../redux/qualifications/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Qualifications = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'Qualifications', key: 'qualifications' }]} />
        <Button
          component={Link}
          to="/qualifications/add"
          variant="contained"
          color="secondary"
        >
          Add Qualification
        </Button>
      </Box>

      <ResourceTable
        headers={[
          { id: 'vto_id', label: 'VTO ID', sortable: true },
          { id: 'national_code', label: 'National Code', sortable: true },
          { id: 'qualification_title', label: 'Qualification Title', sortable: true },
          { id: 'actions', label: '', sortable: false },
        ]}
        filters={[].filter(Boolean)}
        mapResourceToColumns={(qualification) => {
          return [
            { label: qualification.vto_id },
            { label: qualification.national_code },
            { label: qualification.qualification_title },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/qualifications/${qualification.id}`,
                      color: 'secondary',
                      text: 'Manage Qualification',
                      key: 'manage-qualification',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={qualificationsFetchRequested}
        isFetchingResourceSelector={(state) => state.qualifications.fetchingQualifications}
        resourcesSelector={(state) => state.qualifications.qualifications}
        resourceQuerySelector={(state) => state.qualifications.query}
        updateResourceQuerySort={updateQualificationsQuerySort}
        updateResourceQuerySearch={updateQualificationsQuerySearch}
        // updateResourceQueryFilter={updateQualificationsQueryFilter}
        resetResourceQuery={resetQualificationsQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/qualifications/export`}
        searchLabel="Search for qualifications"
      />
    </>
  );
};

export default Qualifications;
