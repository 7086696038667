import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const DetailsList = ({ details }) => {
  const classes = useStyles();

  return (
    <List classes={{ root: classes.list }}>
      {details.map((detail, index) => (
        <ListItem key={index} classes={{ gutters: classes.listItemGutters }}>
          <ListItemText primary={detail.text} secondary={detail.label} />
        </ListItem>
      ))}
    </List>
  );
};

export default DetailsList;
