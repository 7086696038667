import { useSelector } from 'react-redux';

const useStates = () => {
  const states = useSelector((state) => state.apiConfig.config.states);

  return {
    states,
  };
};

export default useStates;
