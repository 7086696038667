import { useSelector } from 'react-redux';

const useEmployers = () => {
  const employers = useSelector((state) => state.apiConfig.config.employers);

  return {
    employers,
  };
};

export default useEmployers;
