import React from 'react';

import DetailsList from '../DetailsList';

const ContactDetails = ({ contact }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Email',
          text: contact.email,
        },
        {
          label: 'Name',
          text: contact.name,
        },
        {
          label: 'Phone',
          text: contact.phone,
        },
        {
          label: 'Fax',
          text: contact.fax,
        },
        {
          label: 'Mobile',
          text: contact.mobile,
        },
      ]}
    />
  );
};

export default ContactDetails;
