import {
  USER_NOTIFY,
  USER_NOTIFY_QUEUE,
  USER_NOTIFY_DEQUEUE,
} from '../actionTypes';

export const userNotify = (msg, success = true) => ({
  type: USER_NOTIFY,
  msg,
  success,
});

export const userNotifyQueue = (msg, success = true) => ({
  type: USER_NOTIFY_QUEUE,
  msg,
  success,
});

export const userNotifyDequeue = () => ({
  type: USER_NOTIFY_DEQUEUE,
});
