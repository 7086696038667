import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';

import config from '../../config';
import { authUserLogoutRequested } from '../../redux/auth/actions';
import SideBar from './SideBar';
import useAuthUser from '../../hooks/useAuthUser';

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    backgroundImage: 'url(/logo.svg)',
    width: '160px',
    height: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: '1px',
    margin: theme.spacing(1),
  },
  accountCircle: {
    marginRight: theme.spacing(1),
  },
  accountButton: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
  },
  menuItemIcon: {
    minWidth: '45px',
  },
  logoBox: {
    width: drawerWidth - theme.spacing(3),
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    authUser,
    authUserIsAuthenticated,
    authUserHasRole,
    authUserIsActive,
  } = useAuthUser();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    dispatch(authUserLogoutRequested());
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.logoBox}>
            <div className={classes.logo} />
          </Box>
          <Typography
            component="h1"
            variant="h3"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {config.name}
          </Typography>
          {authUserIsAuthenticated && (
            <Box>
              <Button
                className={classes.accountButton}
                color="inherit"
                variant="text"
                onClick={handleMenuOpen}
              >
                {authUser.username}
              </Button>
              <Menu
                anchorEl={menuAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                {authUserHasRole && authUserIsActive && (
                  <MenuItem
                    onClick={handleMenuClose}
                    component={Link}
                    to="/profile"
                  >
                    <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                      <AccountCircle />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                    <ExitToApp />
                  </ListItemIcon>
                  Log out
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {authUserIsAuthenticated && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden lgUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <SideBar />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <SideBar />
            </Drawer>
          </Hidden>
        </nav>
      )}
    </>
  );
};

export default NavBar;
