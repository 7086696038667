import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { userNotify, authUserLogoutSucceeded } from '../actions';
import { AUTH_USER_LOGOUT_REQUESTED } from '../auth/actionTypes';

function* logoutAuthUser() {
  yield call(api.logoutUser);
  yield put(authUserLogoutSucceeded());
  yield put(userNotify('Logged out successfully'));
}

function* logoutAuthUserSaga() {
  yield takeLatest(AUTH_USER_LOGOUT_REQUESTED, logoutAuthUser);
}

export default logoutAuthUserSaga;
