import React from 'react';

import DetailsList from '../DetailsList';

const QualificationDetails = ({ qualification }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'VTO ID',
          text: qualification.vto_id,
        },
        {
          label: 'National Code',
          text: qualification.national_code,
        },
        {
          label: 'Qualification Title',
          text: qualification.qualification_title,
        },
      ]}
    />
  );
};

export default QualificationDetails;
